import React from "react";
import InfoIcon from '@mui/icons-material/Info';

const ErrorGWT=()=>{
    return (
        <>
        <div className="error_gwt_notice_Outer">
        <div className="error_gwt_notice">
            <div className="row">
            <div className="icon">
        <InfoIcon/>
        </div>
        <div className="msg">
   
        You have not allowed ESO to access your GWT data. In order to provide access, follow the below steps:
        </div>
            </div>
     
        </div>
      
          <div className="error_gwt">
      <main>
  <ul class="infoGraphic"> 
    <li>
      <div class="numberWrap">
        <div class="number fontColor1">1</div>
        <div class="coverWrap">
          <div class="numberCover"></div>
        </div>
      </div>
      <div class="content">
        <div class="icon iconCodepen"></div>
        <h2>Step 1</h2>
        <p>Go to <a target="_blank" href="https://www.google.com/webmasters/tools/">Webmaster Tools home page,</a>,
  Click on the website you want to monitor and set-up campaign".</p>
      </div>
    </li>
    <li>
      <div class="numberWrap">
        <div class="number fontColor2">2</div>
        <div class="coverWrap">
          <div class="numberCover"></div>
        </div>
      </div>
      <div class="content">
        <div class="icon iconSocial"></div>
        <h2>Step 2</h2>
        <p>Click the gear icon, and then click "Users & Property Owners".</p>
      </div>
    </li>
    <li>
      <div class="numberWrap">
        <div class="number  fontColor3">3</div>
        <div class="coverWrap">
          <div class="numberCover"></div>
        </div>
      </div>
      <div class="content">
        <div class="icon iconAirplane"></div>
        <h2>Step 3</h2>
        <p>Click Add a new user.</p>
      </div>
    </li>
    <li>
      <div class="numberWrap">
        <div class="number  fontColor4">4</div>
        <div class="coverWrap">
          <div class="numberCover"></div>
        </div>
      </div>
      <div class="content">
        <div class="icon iconMap"></div>
        <h2>Step 4</h2>
        <p>Type the email address of the ESO account -
  (<a href="mailto:elitesitemanager@gmail.com">elitesitemanager@gmail.com</a>).</p>
      </div>
    </li>
    <li>
      <div class="numberWrap">
        <div class="number  fontColor5">5</div>
        <div class="coverWrap">
          <div class="numberCover"></div>
        </div>
      </div>
      <div class="content">
        <div class="icon iconBulb"></div>
        <h2>Step 5</h2>
        <p>Select the permission level you want."Restricted" or "Full" (Recommended)</p>
      </div>
    </li>
       <li>
      <div class="numberWrap">
      <div class="number  fontColor6">6</div>
      <div class="coverWrap">
        <div class="numberCover"></div>
      </div>
        </div>
      <div class="content">
        <div class="icon iconPeace"></div>
        <h2>Step 6</h2>
        <p>Then Click Add and refresh this page.</p>
      </div>
    </li>
  </ul>

</main>


        </div>
        </div>
        </>
      
    )
}
export default ErrorGWT