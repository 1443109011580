import { createSlice } from "@reduxjs/toolkit";

const SubscriptionSlice=createSlice({
    name:'usertoken',
    initialState:{value:{eso_user_subscription:localStorage.getItem('eso_user_subscription')}},
    reducers:{
        SetUserSubscription:(state,action)=>{
           localStorage.setItem("eso_user_subscription", action.payload.eso_user_subscription);
           state.value=action.payload
        }
    }
})


export const {SetUserSubscription}=SubscriptionSlice.actions


export default SubscriptionSlice.reducer