import React,{useContext} from 'react';
import { Box, Button, Typography } from '@mui/material';
import { purple } from '@mui/material/colors';
import { Link } from "react-router-dom";
import { AppContext } from '../../../Context/Context';
const primary = "#FFFFFF"; // #f44336

export default function Error() {
  const {isMenuExpanded, setIsMenuExpanded} = useContext(AppContext);

  return (
    <div
    className={`over_all_container error_page ${isMenuExpanded ? "blur overflow-hidden" : ""}`}
  >
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        minHeight: '100vh',
        backgroundColor: primary,
      }}
    >
      <Typography variant="h1" style={{ color: '#0b2033' }}>
        404
      </Typography>
      <Typography variant="h6" style={{ color: '#0b2033' }}>
        The page you’re looking for doesn’t exist.
      </Typography>
      <Link to="/"> <Button variant="contained" sx={{
       textDecoration:"none",
      }}>Back Home</Button></Link>
    </Box>
    </div>
  );
}
