import React,{useContext} from "react";
import { GoogleLogin } from '@react-oauth/google';
import AppleLoginCom from "./AppleLogin";
import { AppContext } from "../../Context/Context";
const GoogleLoginCom=()=>{
  const {isMenuExpanded, setIsMenuExpanded} = useContext(AppContext);


    return(
        <div className={`over_all_container ${isMenuExpanded ? "blur overflow-hidden" : ""}`}>

          <div className="google_apple_log_container">


          <GoogleLogin
            onSuccess={credentialResponse => {

                console.log((credentialResponse.credential))
           
            }}
          
            onError={() => {
              console.log('Login Failed');
            }}
          
          />

          <AppleLoginCom/>
          </div>


        </div>
    )
}
export default GoogleLoginCom