import React, { useState, useEffect } from "react";
import axios from "axios";
import "./Logindetail.css";
import { Link, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import copy from "copy-to-clipboard";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import QuestionMarkIcon from "@mui/icons-material/QuestionMark";
import Rightsidebar from "../Rightsidebar/Rightsidebar"; 

function Googleanalytics (){
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const esodata = useSelector((state) => state);
  const ApiToken=esodata.apiauthkey.value
  const [text, setText] = useState("");
  const [Googleid,SetGoogleid]= useState("");
  const [GoogleidError,SetGoogleidError]= useState("");
  const [apiopen, setapiOpen] = React.useState(false);
  const [apierrorMsg,setapierrorMsg]=useState();
  const [apiOpen, setApiOpen] = useState(false);
  const [apiErrorMsg, setApiErrorMsg] = useState("");
  const [toggle, setToggle] = useState(false);
  const [faqData,setfaqData]=useState("2")
  const [initialfaqDateval,SetInitialfaqDateval] = useState([]);

  const apihandleClose = () => {
    setapiOpen(false);
  };

  useEffect(() => {
    callUserdataApi();
  }, []);
  const ongoogleId = e =>{
    SetGoogleid(e.target.value)
  }
  const faqdatafun = async(menubutval="") => {
    var datavar=`${process.env.REACT_APP_API_URL}domain/frequently_asked_questions/?menu=50`
  
    try {
      const doaminsectiondatavalue = await axios.get(
        datavar,
        {
          headers: {
            Authorization: `${ApiToken}`,
          },
        }
      ).catch((error) => {
        if(error?.response?.status==599 || error?.response?.status==400 || error?.response?.status==500){
          setapiOpen(true);
          setapierrorMsg(error?.response?.data?.error)
        }
        else if(error?.code =="ERR_NETWORK" ||  error?.response?.status==404 ){
          navigate('/errorpages')    
        }
      });
      if (doaminsectiondatavalue?.data) {
        SetInitialfaqDateval(doaminsectiondatavalue?.data?.faqs)
      }
    } catch (err) {}
  }
  useEffect(() => {
    faqdatafun()
  }, []);
  const handleToggle = () => {
    if(faqData!=1){
      setfaqData(1)
    }
    else{
      setfaqData(2)
    }
        setToggle((pre) => !pre);
  };
  const handleCopyClick  = () => {
    copy(text);
    toast.success('Successfully copied to clipboard', {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: false, 
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
      });

  };
  const skipButtonclick = () =>{
    navigate('/')
  }
  const nextButtonclick = async () =>{
    var constval=1;
    SetGoogleidError("")
    if(Googleid===""){
      SetGoogleidError("Google ID is Required")
      constval=2
    }
    if(Googleid!==""){
      if(isNaN(Googleid) || Googleid.length >10){
        SetGoogleidError("Enter Valid Google ID")
      constval=2
      }
    }
    if(constval!=2)
    {
      const response=await axios.post(
        `${process.env.REACT_APP_API_URL}googleanalytics/save_google_analytics_property_id/`,
          {
            google_analytics_propery_id:   Googleid,
            user_domain_id:esodata.domaindata.value.header_selected,

          },
           {
                 headers: {
                   Authorization: `${ApiToken}`,
                 },
               }       
      ).catch((error) => {
        if(error?.response?.status==599 || error?.response?.status==500 || error?.response?.status==400  || error?.response?.status==404){
          setapiOpen(true);
          setapierrorMsg(error?.response?.data?.error)
        }
        else if(error?.code =="ERR_NETWORK"){
          navigate('/errorpages')    
        }
        // console.log("kannan",error)
      });

      if(response?.data?.status_code==200){
        navigate("/")
      }
    }

  }

  const callUserdataApi = async (jobid = "") => {
    setLoading(true);
    try {
      const domain_owber = await axios.get(
        `${process.env.REACT_APP_API_URL}googleanalytics/get_service_account_email/`,
        {
          headers: {
            Authorization: `${ApiToken}`,
          },
        }
      ).catch((error) => {
        if(error?.response?.status==599 || error?.response?.status==500 || error?.response?.status==400  || error?.response?.status==404){
          setapiOpen(true);
          setapierrorMsg(error?.response?.data?.error)
        }
        else if(error?.code =="ERR_NETWORK"){
          navigate('/errorpages')    
        }
        // console.log("kannan",error)
      });
       setText(domain_owber.data.service_email_id)
    
    } catch (err) {}
    setLoading(false);
  };
    return (
      <div className="userdata">
          <ToastContainer
position="top-center"
autoClose={5000}
hideProgressBar={false}
newestOnTop={false}
closeOnClick
rtl={false}
pauseOnFocusLoss
draggable
pauseOnHover
theme="light"
/>
<div className="cnt_hdr_top_rgt_wrap" style={{display:"flex" , justifyContent:"flex-end"}}>
        <div className={`App ${faqData=="1"?'faq_move_class':""}`} style={{padding:"20px"}} >
            <button onClick={handleToggle} className="faq_class"  title="Frequently Asked Question" style={{padding:"10px"}}>
            <QuestionMarkIcon />
              
            </button>
           {toggle && <Rightsidebar close={() => setToggle(false)} initialfaqDateval={initialfaqDateval} />} 
           
          </div>
        
        </div>
        <h1>Google Analytics</h1>
        <div className="outer-container"
    style={{
      width: "calc(100% - 60px)",
      marginRight: "60px",
      marginLeft: "auto",
      marginRight: "auto",
    }}
  >
        <div className="userdata_inner">
        <p>Google Analytics</p>    
        <div class="form-group domain_owner domain_ownertext">
            <label style={{ marginBottom: "-20px" }}>1) Please enter the following service account as user in your analytics account</label>
            <input type="text"  value={text} onChange={(e) => setText(e.target.value)} disabled/>
            <button className="copybutton" onClick={handleCopyClick}>
                Copy 
            </button>
        </div>
        <div class="form-group domain_owner">
          <label>2) Provide Viewer access only</label>
        </div>
        <div class="form-group user_country websitename">
          <label>Google ID:</label>
          <input type="text" name="websitename" onChange={ongoogleId} />
          <span className="error-value">{GoogleidError}</span>
        </div>
         <div className="form_next analytics_button">      
            <button className="nextbutton" type="button" onClick={nextButtonclick}>Next</button>
            <button className="skip" type="button" onClick={skipButtonclick}>Skip</button>
         </div>   
    
        </div>
        </div>
        <Dialog
          open={apiopen}
          onClose={apihandleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle>{"Alert!! "}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              {apierrorMsg}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={apihandleClose}>Close</Button>
          </DialogActions>
        </Dialog>
      </div>   

    );
}

export default Googleanalytics;


