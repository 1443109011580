import { createSlice } from "@reduxjs/toolkit";


const APITOKEN=localStorage.getItem('eso_auth_token')



const esoAPiAuthKeySlice=createSlice({
    name:'api_auth_key',
    initialState:{value:APITOKEN},
    reducers:{
        SetAuthToken:(state,action)=>{

       
           localStorage.setItem("eso_auth_token", action.payload.eso_auth_token);
           state.value=action.payload.eso_auth_token
        }
    }
   
})


export const {SetAuthToken}=esoAPiAuthKeySlice.actions


export default esoAPiAuthKeySlice.reducer