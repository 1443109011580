import React, { useState, useEffect } from "react";
import { styled, useTheme } from "@mui/material/styles";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import axios from "axios";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import { color } from "d3-color";
import { interpolateRgb } from "d3-interpolate";
import { useSelector } from "react-redux";
import { Line } from "react-chartjs-2";
import Box from "@mui/material/Box";
import { useNavigate } from "react-router-dom"
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ChainIcon from "../../../Icons/chain.png";
import ChainWhiteIcon from "../../../Icons/chain-white.png";
import { Helmet } from 'react-helmet';
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";



export default function BacklinksTrend(props) {
  const [cardData, setCardData] = useState([]);
  const [jobidData, setjobidData] = useState("");
  const [loading, setLoading] = useState(false);
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [jobDate, setjobDate] = useState("");
  const [completedate, setcompletedate] = useState("");
  const [selectval, setSelectval] = useState("");
  const [SectionOptions, SetSectionOptions] = useState([]);
  const [campaign, Setcampaign] = useState("");
  const [campaignoption, Setcampaignoption] = useState([]);
  const [ErrorMsg, SetErrorMsg] = useState("");
  const [selectjobid, setselectjobid] = useState("");
  const [SearchEngine, SetSearchEngine] = useState("");
  const [Countryoptions, SetCountryoptions] = useState([]);
  const [Stateoptions, SetStateoptions] = useState([]);
  const [CityOptions, SetCityOptions] = useState([]);
  const [Engineoptions, SetEngineoptions] = useState([]);
  const [Country, SetCountry] = useState("");
  const [State, SetState] = useState("");
  const [City, SetCity] = useState("");
  const esodata = useSelector((state) => state);
  const ApiToken = esodata.apiauthkey.value;
  const [seoindexData, setSeoindexData] = useState([]);
  const [seooccurData, setSeooccurData] = useState([]);
  const [TotalBacklinkCount, SetTotalBacklinkCount] = useState(0)
  const [FollowCount, SetFollowCount] = useState(0)
  const [NofollowCount, SetNofollowCount] = useState(0)
  const navigate = useNavigate()
  const [autoheightval, SetAutoheightval] = useState(true)
  const [apiopen, setapiOpen] = React.useState(false);
  const [apierrorMsg, setapierrorMsg] = useState();
  const [ChainIcons1, setChainIcons1] = useState(ChainIcon);
  const [ChainIcons2, setChainIcons2] = useState(ChainIcon);
  const [ChainIcons3, setChainIcons3] = useState(ChainIcon);
  

  var startColor = "#6495ed"; // cornflowerblue
  var endColor = "#dc143c"; // crimson
  const interpolate = interpolateRgb(startColor, endColor);
  const fillColor = "rgb(255,174,31)";
  const textfillColor = "rgb(255,255,255)";
  const gradientStops = [
    {
      key: "0%",
      stopColor: color(fillColor).darker(0.5).toString(),
      stopOpacity: 1,
      offset: "0%",
    },
    {
      key: "50%",
      stopColor: fillColor,
      stopOpacity: 0.75,
      offset: "50%",
    },
    {
      key: "100%",
      stopColor: color(fillColor).brighter(0.5).toString(),
      stopOpacity: 0.5,
      offset: "100%",
    },
  ];

  const barChartData = {
    labels: seoindexData,
    datasets: [
      {
        label: 'Earlier Occurence',
        data: seooccurData,
        barPercentage: 0.1,
        categoryPercentage: 4,
        borderWidth: 0,
        backgroundColor: ["rgb(255,	174,	31)"],
      },
    ],
  };

  const StyledGridOverlay = styled("div")(({ theme }) => ({
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
    "& .ant-empty-img-1": {
      fill: theme.palette.mode === "light" ? "#aeb8c2" : "#262626",
    },
    "& .ant-empty-img-2": {
      fill: theme.palette.mode === "light" ? "#f5f5f7" : "#595959",
    },
    "& .ant-empty-img-3": {
      fill: theme.palette.mode === "light" ? "#dce0e6" : "#434343",
    },
    "& .ant-empty-img-4": {
      fill: theme.palette.mode === "light" ? "#fff" : "#1c1c1c",
    },
    "& .ant-empty-img-5": {
      fillOpacity: theme.palette.mode === "light" ? "0.8" : "0.08",
      fill: theme.palette.mode === "light" ? "#f5f5f5" : "#fff",
    },
  }));

  const MyCustomNoRowsOverlay = () => (
    <StyledGridOverlay>
      <svg
        width="120"
        height="100"
        viewBox="0 0 184 152"
        aria-hidden
        focusable="false"
      >
        <g fill="none" fillRule="evenodd">
          <g transform="translate(24 31.67)">
            <ellipse
              className="ant-empty-img-5"
              cx="67.797"
              cy="106.89"
              rx="67.797"
              ry="12.668"
            />
            <path
              className="ant-empty-img-1"
              d="M122.034 69.674L98.109 40.229c-1.148-1.386-2.826-2.225-4.593-2.225h-51.44c-1.766 0-3.444.839-4.592 2.225L13.56 69.674v15.383h108.475V69.674z"
            />
            <path
              className="ant-empty-img-2"
              d="M33.83 0h67.933a4 4 0 0 1 4 4v93.344a4 4 0 0 1-4 4H33.83a4 4 0 0 1-4-4V4a4 4 0 0 1 4-4z"
            />
            <path
              className="ant-empty-img-3"
              d="M42.678 9.953h50.237a2 2 0 0 1 2 2V36.91a2 2 0 0 1-2 2H42.678a2 2 0 0 1-2-2V11.953a2 2 0 0 1 2-2zM42.94 49.767h49.713a2.262 2.262 0 1 1 0 4.524H42.94a2.262 2.262 0 0 1 0-4.524zM42.94 61.53h49.713a2.262 2.262 0 1 1 0 4.525H42.94a2.262 2.262 0 0 1 0-4.525zM121.813 105.032c-.775 3.071-3.497 5.36-6.735 5.36H20.515c-3.238 0-5.96-2.29-6.734-5.36a7.309 7.309 0 0 1-.222-1.79V69.675h26.318c2.907 0 5.25 2.448 5.25 5.42v.04c0 2.971 2.37 5.37 5.277 5.37h34.785c2.907 0 5.277-2.421 5.277-5.393V75.1c0-2.972 2.343-5.426 5.25-5.426h26.318v33.569c0 .617-.077 1.216-.221 1.789z"
            />
          </g>
          <path
            className="ant-empty-img-3"
            d="M149.121 33.292l-6.83 2.65a1 1 0 0 1-1.317-1.23l1.937-6.207c-2.589-2.944-4.109-6.534-4.109-10.408C138.802 8.102 148.92 0 161.402 0 173.881 0 184 8.102 184 18.097c0 9.995-10.118 18.097-22.599 18.097-4.528 0-8.744-1.066-12.28-2.902z"
          />
          <g className="ant-empty-img-4" transform="translate(149.65 15.383)">
            <ellipse cx="20.654" cy="3.167" rx="2.849" ry="2.815" />
            <path d="M5.698 5.63H0L2.898.704zM9.259.704h4.985V5.63H9.259z" />
          </g>
        </g>
      </svg>
      <Box sx={{ mt: 1 }} >No Data</Box>
    </StyledGridOverlay>

  );
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };


  const [validation, setValidation] = useState({
    dateerror: "",
  });
  let errors = { ...validation };
  let subtitle;
  let detailurl = `${process.env.REACT_APP_DOMAIN}internal-link-checker/source`;
  let Outdetailurl = `${process.env.REACT_APP_DOMAIN}internal-link-checker/out-bound`;


  const [columns, Setcolumns] = useState([])

  const columnsIni = [

    {
      field: "id",
      headerName: "#",
      minWidth: 80,
      flex: 1,
      disableClickEventBubbling: true,
      headerAlign: "center",
      align: "center",
    },




  ];

  const rows = cardData ?? [];






  function openModal() {
    setIsOpen(true);
  }
  function closeModal() {
    setIsOpen(false);
  }







  const ColorObj = [{

    borderColor: "orange",
    backgroundColor: "rgb(255, 235, 219)",
    fill: {
      target: "origin", // Set the fill options
      above: "rgba(255, 235, 219, 0.3)"
    }

  }]


  const LoadData = async (tableid = '') => {

    var JobId = '';


    if (props.RankJob) {
      if (props.RankJob[0].value) {
        JobId = props.RankJob[0].value
      }
    }



    setLoading(true)
    try {



      const datavalueKpi = await axios.get(
        `${process.env.REACT_APP_API_URL}backlinks/v2/backlinks_trend_count/?user_domain_id=${esodata.domaindata.value.header_selected}&job_id=${JobId}`,
        {
          headers: {
            Authorization: `${ApiToken}`,
          },
        }
      ).catch((error) => {
        if (error?.response?.status == 599 || error?.response?.status == 400 || error?.response?.status == 500) {
          setapiOpen(true);
          setapierrorMsg(error?.response?.data?.error)
        }
        else if (error?.code == "ERR_NETWORK" || error?.response?.status == 404) {
          navigate('/errorpages')
        }
        // console.log("kannan",error)
      });

      if (datavalueKpi?.data) {
        if (datavalueKpi?.data?.total_backlinks_count) {
          SetTotalBacklinkCount(datavalueKpi.data.total_backlinks_count)
        }
        if (datavalueKpi?.data?.total_do_follow_count) {
          SetFollowCount(datavalueKpi.data.total_do_follow_count)
        }
        if (datavalueKpi?.data?.total_no_follow_count) {
          SetNofollowCount(datavalueKpi.data.total_no_follow_count)
        }
      }




    } catch (err) {


      console.log(err)
    }


    try {



      const datavalue = await axios.get(
        `${process.env.REACT_APP_API_URL}backlinks/v2/backlinks_trend_graph/?user_domain_id=${esodata.domaindata.value.header_selected}&job_id=${JobId}`,
        {
          headers: {
            Authorization: `${ApiToken}`,
          },
        }
      ).catch((error) => {
        if (error?.response?.status == 599 || error?.response?.status == 400 || error?.response?.status == 500) {
          setapiOpen(true);
          setapierrorMsg(error?.response?.data?.error)
        }
        else if (error?.code == "ERR_NETWORK" || error?.response?.status == 404) {
          navigate('/errorpages')
        }
        // console.log("kannan",error)
      });

      var ResultData = [];

      if (datavalue?.data) {
        if (datavalue?.data?.result) {
          ResultData = datavalue.data.result
        }
      }


      const labelsIn = ResultData.map(entry => entry.Month.toString());

      const arrayOfKeys = ResultData.map(obj => Object.keys(obj));

      var FrameDataSetKey = arrayOfKeys;
      const FrameDataSetValue = [];

      if (FrameDataSetKey) {
        FrameDataSetKey = FrameDataSetKey[0]
        FrameDataSetKey = FrameDataSetKey.filter(item => item !== 'Month');
        FrameDataSetKey.forEach(element => {
          const arrayValues = ResultData.map(obj => obj[element]);
          const Cobj = ColorObj[0];
          const object1 = {
            label: element,
            data: arrayValues,
          }
          const mergedObject = { ...object1, ...Cobj };
          FrameDataSetValue.push(object1)
        });

      }


      SetLabelData(labelsIn)


      props.setjobDate(moment(datavalue?.data?.job_date).format("MMMM DD,YYYY HH:mm"));
      SetDataSetsValue(FrameDataSetValue)





    } catch (err) {

      SetLabelData([])

      console.log(err)
    }


    try {





      const datavalueTable = await axios.get(
        `${process.env.REACT_APP_API_URL}backlinks/v2/backlinks_trend_table/?user_domain_id=${esodata.domaindata.value.header_selected}&job_id=${JobId}`,
        {
          headers: {
            Authorization: `${ApiToken}`,
          },
        }
      ).catch((error) => {
        if (error?.response?.status == 599 || error?.response?.status == 400 || error?.response?.status == 500) {
          setapiOpen(true);
          setapierrorMsg(error?.response?.data?.error)
        }
        else if (error?.code == "ERR_NETWORK" || error?.response?.status == 404) {
          navigate('/errorpages')
        }
        // console.log("kannan",error)
      });

      var labelsIn = []

      if (datavalueTable?.data?.table_headers) {
        labelsIn = datavalueTable.data.table_headers
      }

      var ColumnsMerge = [];

      labelsIn.forEach(element => {

        var Height = 200;

        if (element === 'Domains') {
          Height = 300
        }


        ColumnsMerge.push({

          field: element,
          headerName: element,
          minWidth: Height,
          flex: 1,
          disableClickEventBubbling: true,
          headerAlign: "center",
          align: "center",





        })


      });



      ColumnsMerge = columnsIni.concat(ColumnsMerge)
      Setcolumns(ColumnsMerge)






      const dataval = datavalueTable?.data?.table_body;





      if (dataval) {
        const newData = dataval.map((obj, index) => {
          return { ...obj, id: index + 1 };
        });

        setCardData(newData);
        SetAutoheightval(false)
        props.setjobDate(moment(datavalueTable?.data?.job_date).format("MMMM DD,YYYY HH:mm"));

      }
      else {
        setCardData([]);
        SetAutoheightval(true)
      }





    } catch (err) {
      setCardData([])

      console.log(err)
    }

    setLoading(false)

  }


  const [LabelData, SetLabelData] = useState([])

  const [DataSetsValue, SetDataSetsValue] = useState([])







  const chartData = {
    labels: LabelData,
    datasets: DataSetsValue
  };

  const chartOptions = {
    scales: {
      x: {
        type: 'category', // Use 'category' for string x-axis values
        position: 'bottom',
        ticks: {
          color: '#0b2033'
        },

      },
      y: {

        ticks: {
          color: '#0b2033'
        },

      },

    },
    plugins: {
      legend: {
        display: false

      }
    }
  };




  useEffect(() => {
    LoadData()
  }, []);

  useEffect(() => {

    if (props.FilterButtonTriggered === true) {


      LoadData()
      props.SetFilterButtonTriggered(false)
      props.closeModal()
    }

  }, [props])


  const apihandleClose = () => {
    setapiOpen(false);
  };

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Backlinks Trend</title>
      </Helmet>
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      {/* {loading === true ? <Loader /> : null} */}

      <div className="external_link_data_row back_link_trend_data_row">
        <div className="cols"
          onMouseEnter={()=> {setChainIcons1(ChainWhiteIcon)}}
          onMouseLeave={()=> {setChainIcons1(ChainIcon)}}
        >
          <div className="icon_col">
            <img src={ChainIcons1} />
          </div>
          <div className="content_col">
            <p>Total Backlinks</p>
            <p>{TotalBacklinkCount}</p>
          </div>
        </div>
        <div className="cols"
          onMouseEnter={()=> {setChainIcons2(ChainWhiteIcon)}}
          onMouseLeave={()=> {setChainIcons2(ChainIcon)}}  
        >
          <div className="icon_col">
            <img src={ChainIcons2} />
          </div>
          <div className="content_col">
            <p>Follow</p>
            <p>{FollowCount}</p>
          </div>
        </div>
        <div className="cols"
          onMouseEnter={()=> {setChainIcons3(ChainWhiteIcon)}}
          onMouseLeave={()=> {setChainIcons3(ChainIcon)}}  
        >
          <div className="icon_col">
            <img src={ChainIcons3} />
          </div>
          <div className="content_col">
            <p>No follow</p>
            <p>{NofollowCount}</p>
          </div>
        </div>

      </div>


      <div className="site_regress_chart_info">
        {/* <p>Site Audit Summary by Issues</p> */}
        <div className="site_Chart_info_inner">


          <Line data={chartData} options={chartOptions} />
          <p>Backlinks Trends</p>




        </div>

      </div>



      <div
        className="data_table internal_link_checker_table"
        style={{ width: "100%" }}
      >
        <div style={{ height: 550, width: "100%", "margin-top": "5%" }}>
          <DataGrid

            slots={{
              noRowsOverlay: MyCustomNoRowsOverlay,
            }}
            rows={rows}
            columns={columns}
            sx={{
              "&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell": {
                py: "8px",
              },
              "&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell": {
                py: "15px",
              },
              "&.MuiDataGrid-root--densityComfortable .MuiDataGrid-cell":
              {
                py: "22px",
              },
              "& .MuiDataGrid-columnHeaderTitle": {
                whiteSpace: "normal",
                lineHeight: "normal",
              },
              "&, [class^=MuiDataGrid]": { border: "none" },
              ".MuiDataGrid-virtualScroller": {
                height: "auto !important",
                overflowY: "auto",
              },
              "& .MuiDataGrid-cell:first-child": {
                position: "unset",
                top: "0",
                left: "0",
                paddingLeft: "1.5rem",
                zIndex: 999,
              },
              "& .MuiDataGrid-columnHeader:first-child": {
                position: "sticky",
                top: "0",
                left: "0",
                paddingLeft: "1.5rem",
                border: "none",
                zIndex: 999,
              },
              "& .MuiDataGrid-columnSeparator--sideRight": {
                display: "none"
              },
            }}
            gridRowId={(row) => row.id}
            getRowHeight={() => "auto"}
            autoHeight={autoheightval ? true : false}
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: 10,
                },
              },
            }}
            pageSizeOptions={[10, 30, 50, 75, 100]}
            disableColumnFilter
            disableColumnSelector
            disableDensitySelector
            // loading={loading}
          />
        </div>
        <Dialog
          open={apiopen}
          onClose={apihandleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle>{"Alert!! "}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              {apierrorMsg}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={apihandleClose}>Close</Button>
          </DialogActions>
        </Dialog>
      </div>

    </>
  );
}
