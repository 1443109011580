// axiosConfig.js

import axios from 'axios';


axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
 
    const status = error.response?.status

    if (status===401) {
        localStorage.setItem("eso_auth_token",'');
        localStorage.setItem("eso_user_token",'');

        
        if(window.location.pathname!=='/login')
        {  
            window.location = `${window.location.protocol}//${window.location.host}/login`;
        }
 
    } else {
      return Promise.reject(error);
    }
  }
);

export default axios;
