import React,{useContext} from 'react';
import { Box, Button, Typography } from '@mui/material';
// import { purple } from '@mui/material/colors';
import { Link } from "react-router-dom";
//mport errorimages from "./Asset/errorsimage.png";
import errorimages from "../Asset/errorsimage.png"
import { AppContext } from '../../../Context/Context';
const primary = "#FFFFFF"; // #f44336

export default function Errorpages() {
  const {isMenuExpanded, setIsMenuExpanded} = useContext(AppContext);
  return (
    <div
    className={`over_all_container error_page ${isMenuExpanded ? "blur overflow-hidden" : ""}`}
  >
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        minHeight: '100vh',
        backgroundColor: primary,
      }}
    >
      <Typography variant="h1" style={{ color: '#0b2033' }}>
      <img src={errorimages} alt="errimg" title="Errorimage"/>
      </Typography>
      <Typography variant="h6" style={{ color: '#0b2033',"text-align":'center',"margin":"0px 4px" }}>
      Unfortunately, we're currently experiencing some issues processing your request. Please wait a few minutes and try again. Thank you for your patience!

      </Typography>
      <p>Further queries please contact <b>support@elitesiteoptimizer.com</b></p>
      <Link to="/"> <Button variant="contained" sx={{
       textDecoration:"none",
      }}>Back Home</Button></Link>
    </Box>
    </div>
  );
}
