import React, { useState, useRef, useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useLocation } from 'react-router-dom'

const LoginMiddleWare=()=>{

    const esodata = useSelector((state) => state);
    const location = useLocation();
  

    const navigate = useNavigate();

    const CheckUserTokenExists=()=>{
  
        
 

        if(esodata.usertoken.value.eso_user_token===undefined || esodata.usertoken.value.eso_user_token===null || esodata.usertoken.value.eso_user_token==='')
        {
          if(location.pathname!=='login')
          {  
              window.location = `${window.location.protocol}//${window.location.host}/login`;
          }
       
        }
        else {
          if(esodata.usersubscription.value.eso_user_subscription=='true' || esodata.usersubscription.value.eso_user_subscription==true)
          {
         

            if(location.pathname!=='/subscription/auth-check')
            {  
                window.location = `${window.location.protocol}//${window.location.host}/subscription/auth-check`;
            }
          }
          else if (esodata.firsttimelogin.value.eso_user_first_time_login=='true' || esodata.firsttimelogin.value.eso_user_first_time_login==true)
          {
            if(location.pathname!=='/subscription/auth-check')
            {  
                window.location = `${window.location.protocol}//${window.location.host}/subscription/auth-check`;
            }
          }
        }
    
    
    
      }

    useEffect(()=>{
        CheckUserTokenExists()
       

    })


    return(
        <>
        </>
    )


}

export default LoginMiddleWare