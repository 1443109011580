import "./login.css";
import Logo from "../NavBar/Asset/New_Assert/ESO_Logo.png";
import Eso_rst_img from './Asset/On_Board/resetLink.gif';
function ResetSuccess(){

    return (
        <>
            <div className="rest_scs_outer_container">
               <div className="rest_scs_ins_container">
                <img className="Eso_logo" src={Logo} alt="Eso logo" />
                <img className="Eso_rst_img" src={Eso_rst_img} alt="animation gif" />
                <h1 className="scs_tt">The reset link has been sent!</h1>
                <div className="rest_inst_wrap">
                If you don’t receive an email in the next 24 hours, please contact our support team via contact form or by email at <span className="rst_scs_in_mail"><a href="mailto:contact@elitesiteoptimizer.com">contact@elitesiteoptimizer.com</a></span>
                </div>
               </div>
            </div>

        </>
       
    );
}
export default ResetSuccess;