import React, { useState, useEffect, useCallback, useMemo } from "react";
import { useSelector } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const WordFile = (props) => {
  const [childcardData, setChildcardData] = useState([]);
  const navigate = useNavigate();
  const esodata = useSelector((state) => state);
  const ApiToken = esodata.apiauthkey.value;
  const { jobid } = useParams();

  useEffect(() => {
    setChildcardData(props.cardData);
  }, [props.cardData]);

  const successRows = useMemo(() => (
    childcardData.detailed_data?.success?.map((row, index) => (
      <tr key={index}>
        <td>{index + 1}</td>
        <td>{row}</td>
      </tr>
    ))
  ), [childcardData.detailed_data?.success]);

  const failureRows = useMemo(() => (
    childcardData.detailed_data?.failure?.map((row, index) => (
      <tr key={index}>
        <td>{index + 1}</td>
        <td>{row}</td>
      </tr>
    ))
  ), [childcardData.detailed_data?.failure]);

  return (
    <div className="cnt_hdr_file_access">
      <div className="cnt_hdr_content_box_outer fileaccesstopinner">
        <div className="plagiarisam_missing_waring_wrap filename">
          <p>{childcardData.file_name}</p>
          <p><b>File Name</b></p>
        </div>
        <div className="plagiarisam_missing_waring_wrap">
          <p>{childcardData.score}</p>
          <p><b>Score</b></p>
        </div>
      </div>
      <div className="cnt_hdr_content_table fileaccessreport_inner">
        <div className="box_wrap" style={{ marginLeft: "30px" }}>
          <div className="hor_line">
            <h3>Success</h3>
          </div>
          <table className="table">
            <col style={{ width: "5%" }} />
            <col style={{ width: "35%" }} />
            <col style={{ width: "50%" }} />
            <col style={{ width: "20%" }} />
            <tbody>
              {successRows}
            </tbody>
          </table>
        </div>
      </div>
      <div className="cnt_hdr_content_table fileaccessreport_inner">
        <div className="box_wrap" style={{ marginLeft: "30px" }}>
          <div className="hor_line">
            <h3>Failure</h3>
          </div>
          <table className="table">
            <col style={{ width: "5%" }} />
            <col style={{ width: "35%" }} />
            <col style={{ width: "50%" }} />
            <col style={{ width: "20%" }} />
            <tbody>
              {failureRows}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default WordFile;
