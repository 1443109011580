import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import AppleLogin from "react-apple-login";
import AppleIcon from '@mui/icons-material/Apple';
import { jwtDecode } from "jwt-decode";



const AppleLoginCom=()=>{


    const [AppleId,SetAppleId]=useState('kavitha@egrovesys.com')
    const appleResponse=(e)=>{

        if(e.authorization)
        {

            if(e.authorization.id_token)
            {
                console.log((e.authorization.id_token))
            }

        }
        console.log('appleResponse12',e)
        console.log("userdata",e.user)
        if(!(e.user)){
            console.log("hiii")
            const idToken = jwtDecode(e.authorization.id_token);
            console.log("token",idToken.email)
        }
        else{
            console.log('out',e.user.name.firstName)
            console.log('out',e.user.name.lastName)
            console.log('out',e.user.email)

        }
       


    }

    useEffect(()=>{

    },[AppleId])


    return(
        <div>
          <AppleLogin


    clientId={process.env.REACT_APP_APPLE_LOGIN_CLIENT_ID}
    redirectURI={process.env.REACT_APP_APPLE_LOGIN_REDIRECT_PATH}
    usePopup={true}
    callback={appleResponse}
  // Catch the response
  responseType={"code"} 
    scope="name email"
    responseMode="query"
    render={renderProps => (  //Custom Apple Sign in Button
    // <button
 
     
    //     >
    //       <i className="fa-brands fa-apple px-2 "></i>
    //       Continue with Apple
    // </button>

<button class="apple-sign-in"      onClick={renderProps.onClick}>
<AppleIcon/>
<span>Sign in with Apple</span>

</button>

    
      )}
 
  />
        </div>
    )
}
export default AppleLoginCom