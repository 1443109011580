import React, { useState, useEffect ,useContext} from "react";
import { Link, useParams } from "react-router-dom";
import pdficon from "./../Asset/PDF.png";
import csvicon from "./../Asset/CSV.png";
import axios from "axios";
import LiquidFillGauge from "react-liquid-gauge";
import { color } from "d3-color";
import { useSelector } from "react-redux";
import TabContext from "@mui/lab/TabContext";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { styled, useTheme } from "@mui/material/styles";
import moment from "moment";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import trueimages from "./../Asset/true-images.png";
import failimages from "./../Asset/fail-images.png";
import warningimages from "./../Asset/warning-icon.png";
import { ToastContainer, toast } from 'react-toastify';
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import { useNavigate } from "react-router-dom";

import "./Performancestyle.css";
import Loader from "../CommonLoader";
import { Commonfun } from './../Commonfun';
import { timezonechange } from './../Commonfun';
import { timezoneval } from './../Commonfun';
import ResetPng from "../../../Icons/reset.png";
import RestorePng from "../../../Icons/restore.png";
import { AppContext } from "../../../Context/Context";
const AccessbilityScoreDetails = () => {
  let querystring = useParams();
  const [expanded, setExpanded] = React.useState(false);
  const {isMenuExpanded, setIsMenuExpanded} = useContext(AppContext);

  const handleChanged = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const apihandleClose = () => {
    setapiOpen(false);
  };
  const ItemId = querystring.id;
  const JobId = querystring.jobid;
  const esodata = useSelector((state) => state);
 const detail_Render_url=`${process.env.REACT_APP_DOMAIN}performance/render-blocking/${ItemId}`
 const leverage_browser_url=`${process.env.REACT_APP_DOMAIN}performance/leverage/${ItemId}`

  const [jobDate, SetjobDate] = useState("");
  const [queryurl, Setqueryurl] = useState("");
  const ApiToken = esodata.apiauthkey.value;
  const [loading, setLoading] = useState(false);
  const [jobidData, setjobidData] = useState("");
  const [domainSecdata,setDomainSecdata]=useState("");
  const [apiopen, setapiOpen] = React.useState(false);
  const [apierrorMsg,setapierrorMsg]=useState();
  const [DetailRunReportPopShow,SetDetailRunReportPopShow]=useState()
  
  const [HardRunChecked, SetHardRunChecked] = useState(false);
  const [SoftRunChecked, SetSoftRunChecked] = useState(true);
  const navigate = useNavigate();
  const fillColor = "rgb(255,174,31)";
  const textfillColor = "rgb(255,255,255)";
  const gradientStops = [
    {
      key: "0%",
      stopColor: color(fillColor).darker(0.5).toString(),
      stopOpacity: 1,
      offset: "0%",
    },
    {
      key: "50%",
      stopColor: fillColor,
      stopOpacity: 0.75,
      offset: "50%",
    },
    {
      key: "100%",
      stopColor: color(fillColor).brighter(0.5).toString(),
      stopOpacity: 0.5,
      offset: "100%",
    },
  ];
  const [totalpercecntwords, setTotalpercentwords] = useState("");
  var state = {
    value: totalpercecntwords,
  };

  const [value, setValue] = useState("1");
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const StyledGridOverlay = styled("div")(({ theme }) => ({
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
    "& .ant-empty-img-1": {
      fill: theme.palette.mode === "light" ? "#aeb8c2" : "#262626",
    },
    "& .ant-empty-img-2": {
      fill: theme.palette.mode === "light" ? "#f5f5f7" : "#595959",
    },
    "& .ant-empty-img-3": {
      fill: theme.palette.mode === "light" ? "#dce0e6" : "#434343",
    },
    "& .ant-empty-img-4": {
      fill: theme.palette.mode === "light" ? "#fff" : "#1c1c1c",
    },
    "& .ant-empty-img-5": {
      fillOpacity: theme.palette.mode === "light" ? "0.8" : "0.08",
      fill: theme.palette.mode === "light" ? "#f5f5f5" : "#fff",
    },
  }));
  const MyCustomNoRowsOverlay = () => (
    <StyledGridOverlay>
      <svg
        width="120"
        height="100"
        viewBox="0 0 184 152"
        aria-hidden
        focusable="false"
      >
        <g fill="none" fillRule="evenodd">
          <g transform="translate(24 31.67)">
            <ellipse
              className="ant-empty-img-5"
              cx="67.797"
              cy="106.89"
              rx="67.797"
              ry="12.668"
            />
            <path
              className="ant-empty-img-1"
              d="M122.034 69.674L98.109 40.229c-1.148-1.386-2.826-2.225-4.593-2.225h-51.44c-1.766 0-3.444.839-4.592 2.225L13.56 69.674v15.383h108.475V69.674z"
            />
            <path
              className="ant-empty-img-2"
              d="M33.83 0h67.933a4 4 0 0 1 4 4v93.344a4 4 0 0 1-4 4H33.83a4 4 0 0 1-4-4V4a4 4 0 0 1 4-4z"
            />
            <path
              className="ant-empty-img-3"
              d="M42.678 9.953h50.237a2 2 0 0 1 2 2V36.91a2 2 0 0 1-2 2H42.678a2 2 0 0 1-2-2V11.953a2 2 0 0 1 2-2zM42.94 49.767h49.713a2.262 2.262 0 1 1 0 4.524H42.94a2.262 2.262 0 0 1 0-4.524zM42.94 61.53h49.713a2.262 2.262 0 1 1 0 4.525H42.94a2.262 2.262 0 0 1 0-4.525zM121.813 105.032c-.775 3.071-3.497 5.36-6.735 5.36H20.515c-3.238 0-5.96-2.29-6.734-5.36a7.309 7.309 0 0 1-.222-1.79V69.675h26.318c2.907 0 5.25 2.448 5.25 5.42v.04c0 2.971 2.37 5.37 5.277 5.37h34.785c2.907 0 5.277-2.421 5.277-5.393V75.1c0-2.972 2.343-5.426 5.25-5.426h26.318v33.569c0 .617-.077 1.216-.221 1.789z"
            />
          </g>
          <path
            className="ant-empty-img-3"
            d="M149.121 33.292l-6.83 2.65a1 1 0 0 1-1.317-1.23l1.937-6.207c-2.589-2.944-4.109-6.534-4.109-10.408C138.802 8.102 148.92 0 161.402 0 173.881 0 184 8.102 184 18.097c0 9.995-10.118 18.097-22.599 18.097-4.528 0-8.744-1.066-12.28-2.902z"
          />
          <g className="ant-empty-img-4" transform="translate(149.65 15.383)">
            <ellipse cx="20.654" cy="3.167" rx="2.849" ry="2.815" />
            <path d="M5.698 5.63H0L2.898.704zM9.259.704h4.985V5.63H9.259z" />
          </g>
        </g>
      </svg>
      <Box sx={{ mt: 1 }}>No Data</Box>
    </StyledGridOverlay>
  );

  const columns = [
    {
      field: "code",
      headerName: "Code(s)",
      description: "Seo Metrics from site audit report",
      width: 350,
      disableClickEventBubbling: true,
      headerAlign: "center",
      align: "center",
      sortable: false,
      headerClassName: "hide_option",
    },
    {
        field: "technique",
        headerName: "Technique(s)",
        description: "Number of occurences from site audit report",
        width: 450,
        headerAlign: "center",
        align: "center",
        renderCell: (ErrorCarddata) => {
          return (
          (ErrorCarddata?.row?.techniques).toString()
          );
        },
      },
    {
      field: "tag",
      headerName: "Tag",
      description: "Number of occurences from site audit report",
      width: 100,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "msg",
      headerName: "Message ",
      description: "Number of occurences from site audit report",
      width: 350,

      headerAlign: "center",
      align: "center",
    },

    {
      field: "element",
      headerName: "Element",
      description: "Number of occurences from site audit report",
      width: 350,

      headerAlign: "center",
      align: "center",
    },
  ];
  const columns1 = [
      {
      field: "code",
      headerName: "Code(s)",
      description: "Seo Metrics from site audit report",
      width: 350,
      disableClickEventBubbling: true,
      headerAlign: "center",
      align: "center",
      sortable: false,
      headerClassName: "hide_option",
    },
    {
        field: "technique",
        headerName: "Technique(s)",
        description: "Number of occurences from site audit report",
        width: 450,
        headerAlign: "center",
        align: "center",
        renderCell: (WarningsCarddata) => {
          return (
          (WarningsCarddata?.row?.techniques).toString()
          );
        },
      },
    {
      field: "tag",
      headerName: "Tag",
      description: "Number of occurences from site audit report",
      width: 100,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "msg",
      headerName: "Message ",
      description: "Number of occurences from site audit report",
      width: 350,

      headerAlign: "center",
      align: "center",
    },

    {
      field: "element",
      headerName: "Element",
      description: "Number of occurences from site audit report",
      width: 350,

      headerAlign: "center",
      align: "center",
    },
  ];
  const columns2 = [
    {
    field: "code",
    headerName: "Code(s)",
    description: "Seo Metrics from site audit report",
    width: 350,
    disableClickEventBubbling: true,
    headerAlign: "center",
    align: "center",
    sortable: false,
    headerClassName: "hide_option",
  },
  {
      field: "technique",
      headerName: "Technique(s)",
      description: "Number of occurences from site audit report",
      width: 450,
      headerAlign: "center",
      align: "center",
      renderCell: (NoticesCarddata) => {
        return (
        (NoticesCarddata?.row?.techniques).toString()
        );
      },
    },
  {
    field: "tag",
    headerName: "Tag",
    description: "Number of occurences from site audit report",
    width: 100,
    headerAlign: "center",
    align: "center",
  },
  {
    field: "msg",
    headerName: "Message ",
    description: "Number of occurences from site audit report",
    width: 350,

    headerAlign: "center",
    align: "center",
  },

  {
    field: "element",
    headerName: "Element",
    description: "Number of occurences from site audit report",
    width: 350,

    headerAlign: "center",
    align: "center",
  },
];

  const [ErrorCarddata, SetErrorCarddata] = useState([]);
  const [WarningsCarddata, SetWarningsCarddata] = useState([]);
  const [NoticesCarddata, SetNoticesCarddata] = useState([]);
  const [ErrorCardcount, SetErrorCardcount] = useState("");
  const [WarningCardcount, SetWarningCardcount] = useState("");
  const [NoticeCardcount, SetNoticeCardcount] = useState("");
  const SetRunType = (e) => {
    SetHardRunChecked(false);
    SetSoftRunChecked(false);

    if (e.target.value == "hard") {
      SetHardRunChecked(e.target.checked);
    }
    if (e.target.value == "soft") {
      SetSoftRunChecked(e.target.checked);
    }
  };
  const RunReportTrigger = () => {

    var RunType=0;
   
    if(SoftRunChecked==true)
    {
        RunType=1
    }
    else if (HardRunChecked==true)
    {
        RunType=0
    }
    
  
    CloseModalTrigger()
  };
  const AccessbilityScoreDetail = async () => {    
    const datavalue = await axios.get(
        `${process.env.REACT_APP_API_URL}technical/v2/performance_report/?user_domain_id=${esodata.domaindata.value.header_selected}&link_id=${ItemId}&job_id=${JobId}`,{
          headers: {
            Authorization: `${ApiToken}`,
          },
        }
      ).catch((error) => {
        if(error?.response?.status==599 || error?.response?.status==400 || error?.response?.status==500){
          setapiOpen(true);
          setapierrorMsg(error?.response?.data?.error)
        }
        else if(error?.code =="ERR_NETWORK" || error?.response?.status==404 ){
          navigate('/errorpages')    
        }
        // console.log("kannan",error)
      });
      if(datavalue?.data){
        const dataval = datavalue?.data?.performance_report;
        SetWarningsCarddata(datavalue?.data)
         SetErrorCarddata(dataval)
      }
   
  };
 
  function generateRandom() {
    var length = 8,
        charset = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789",
        retVal = "";
    for (var i = 0, n = charset.length; i < length; ++i) {
        retVal += charset.charAt(Math.floor(Math.random() * n));
    }
    return retVal;
}
  useEffect(() => {
    AccessbilityScoreDetail();
  }, []);
  const pdfdown = async () =>{ 

    setLoading(true);
    try{
      SetDetailRunReportPopShow(false)
    const response = await fetch(`${process.env.REACT_APP_API_URL}technical/v2/performance_report/?user_domain_id=${esodata.domaindata.value.header_selected}&formats=pdf&job_id=${JobId}&link_id=${ItemId}&timezone=${timezoneval()}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/pdf',
                    Authorization: `${ApiToken}`,
                },
            });
            // Check if the request was successful (status code 200)
            if (response.ok) {
                // Convert the response to a blob
                const blob = await response.blob();

                // Create a temporary anchor element
                const link = document.createElement('a');
                link.href = window.URL.createObjectURL(blob);
                var filedata=await Commonfun("PerformanceDetail","pdf", ApiToken,esodata.domaindata.value.header_selected);

                link.download = filedata;
                link.click();  
                // Remove the anchor element
                window.URL.revokeObjectURL(link.href);
            } else {
              toast.error("No Data", {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
                });
                console.error('Failed to fetch PDF:', response.status, response.statusText);
            }
        } catch (error) {
          toast.error("No Data", {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            });
            console.error('Error during PDF download:', error);
        }
        setLoading(false);

  }     


  const pdfsummarydown = async () =>{ 

    setLoading(true);
    try{
      SetDetailRunReportPopShow(false)
    const response = await fetch(`${process.env.REACT_APP_API_URL}technical/v2/performance_pdf_summary/?user_domain_id=${esodata.domaindata.value.header_selected}&job_id=${JobId}&formats=pdf&link_id=${ItemId}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/pdf',
                    Authorization: `${ApiToken}`,
                },
            });
            // Check if the request was successful (status code 200)
            if (response.ok) {
                // Convert the response to a blob
                const blob = await response.blob();

                // Create a temporary anchor element
                const link = document.createElement('a');
                link.href = window.URL.createObjectURL(blob);
                var filedata=await Commonfun("PerformanceDetailSummary","pdf", ApiToken,esodata.domaindata.value.header_selected);

                link.download = filedata;
                link.click();  
                // Remove the anchor element
                window.URL.revokeObjectURL(link.href);
            } else {
              toast.error("No Data", {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
                });
                console.error('Failed to fetch PDF:', response.status, response.statusText);
            }
        } catch (error) {
          toast.error("No Data", {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            });
            console.error('Error during PDF download:', error);
        }
        setLoading(false);

  }
const openMOdaltrigger = () =>{
  SetDetailRunReportPopShow(true);
};
  const CloseModalTrigger = () => {
    SetDetailRunReportPopShow(false);
  };
  return (
    <div className={`over_all_container ${isMenuExpanded ? "blur overflow-hidden" : ""}`}>
      {loading === true ? <Loader /> : null}

       <ToastContainer
position="top-center"
autoClose={5000}
hideProgressBar={false}
newestOnTop={false}
closeOnClick
rtl={false}
pauseOnFocusLoss
draggable
pauseOnHover
theme="light"
/>
      <div className="cnt_audit_outer_wrap">
        <div className="cnt_hdr_top_wrap">
          <div className="cnt_hdr_top_lft_wrap">
            <h2>Performance Detail Report For</h2>
            <p><b>{WarningsCarddata?.link_url}</b></p>
            {/* <p>as of {moment(WarningsCarddata?.job_date).format("MMMM DD,YYYY HH:mm")}</p> */}
         <p>as of {timezonechange(moment(WarningsCarddata?.job_date).format("MMMM DD,YYYY HH:mm"))}  <span style={{ fontWeight: 'bold' }}> - {timezoneval()} </span></p>

          </div>
          <div className="cnt_hdr_top_rgt_wrap">
          <img src={pdficon} alt="pdf icon" onClick={openMOdaltrigger}/>
          </div>
        </div>

        <div className="cnt_hdr_content">
          <div className="box_wrap">
            <div>
              <h3> </h3>
              <p></p>
            </div>
            <div id="performance-metric" className="container">
        <h2>Performance</h2>
       <div className="performance_accordion_div">
       <Accordion expanded={expanded === 'panel1'} onChange={handleChanged('panel1')}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
          <Typography sx={{ width: '33%', flexShrink: 0 }}>
          Optimized Images
          </Typography>
          <Typography sx={{ color: 'text.secondary' }}>{ErrorCarddata?.uses_optimized_images?.result }</Typography>
          <Typography sx={{ color: 'text.secondary',margin:"0px auto" }}>{ErrorCarddata?.uses_optimized_images?.status === "success" ?   <img
                src={trueimages}
                className="view_per_img"
                alt="view report icon"

              /> : ErrorCarddata?.uses_optimized_images?.status !== "danger" ?   <img
              src={failimages}
              className="view_per_img"
              alt="view report icon"

            /> :   <img
            src={warningimages}
            className="view_per_img"
            alt="view report icon"

          />  }</Typography>
        </AccordionSummary>
        <AccordionDetails>
        {ErrorCarddata?.uses_optimized_image_view_report?.optimized_images_title ?.title ?
        (
          <>
          <Typography className="error_result_data">
              <center><h3>{ErrorCarddata?.uses_optimized_image_view_report?.optimized_images_title?.title}</h3></center>
          </Typography>
          <Typography>
              {ErrorCarddata?.uses_optimized_image_view_report?.optimized_images_title?.result}
          </Typography>
          </>
      )
:
        (
          <>
          <Typography className="error_result_data">
          <center><h3>{ErrorCarddata?.uses_optimized_image_view_report?.optimized_image_not_title?.result}</h3></center>
      </Typography>
          
          <Typography>
          {ErrorCarddata && ErrorCarddata.uses_optimized_image_view_report && ErrorCarddata.uses_optimized_image_view_report.item_data && ErrorCarddata?.uses_optimized_image_view_report?.item_data.length > 0 ?
              (ErrorCarddata?.uses_optimized_image_view_report?.item_data).map((itm, inx) => {
                return (
                <p className="itemdata">
                    {itm}
                    </p>
                );
              }): null }
          </Typography>
          </>
          )
}
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === 'panel2'} onChange={handleChanged('panel2')}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
          <Typography sx={{ width: '33%', flexShrink: 0 }}>
         Page Speed
          </Typography>
          <Typography sx={{ color: 'text.secondary' }}>{ErrorCarddata?.page_speed?.result}</Typography>
          <Typography sx={{ color: 'text.secondary',margin:"0px auto" }}>{ErrorCarddata?.page_speed?.status === "success" ?   <img
                src={trueimages}
                className="view_per_img"
                alt="view report icon"

              /> : ErrorCarddata?.page_speed?.status === "danger" ?   <img
              src={failimages}
              className="view_per_img"
              alt="view report icon"

            /> :   <img
            src={warningimages}
            className="view_per_img"
            alt="view report icon"

          />  }</Typography>
        </AccordionSummary>
        <AccordionDetails>
        <Typography className="error_result_data">
        <center> <h3> 
          {ErrorCarddata?.page_speed_view_report?.result}
         </h3></center> </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === 'panel3'} onChange={handleChanged('panel3')}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
          <Typography sx={{ width: '33%', flexShrink: 0 }}>
          Leverage Browser Cache
          </Typography>
          <Typography sx={{ color: 'text.secondary' }}>{ErrorCarddata?.leverage_browser_Cache?.title} </Typography>
          <Typography sx={{ color: 'text.secondary',margin:"0px auto" }}>{ErrorCarddata?.leverage_browser_Cache?.status === "success" ?   <img
                src={trueimages}
                className="view_per_img"
                alt="view report icon"

              /> : ErrorCarddata?.leverage_browser_Cache?.status === "danger" ?   <img
              src={failimages}
              className="view_per_img"
              alt="view report icon"

            /> :   <img
            src={warningimages}
            className="view_per_img"
            alt="view report icon"

          />  }</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography className="error_result_data">
         <center> <h3 >{ErrorCarddata?.liverage_browser_cahce_view_report?.cache?.result} </h3></center>
          </Typography>
          <Typography>
          {ErrorCarddata && ErrorCarddata.liverage_browser_cahce_view_report && ErrorCarddata.liverage_browser_cahce_view_report.item_data_report && ErrorCarddata.liverage_browser_cahce_view_report.item_data_report.length > 0 ?
              (ErrorCarddata?.liverage_browser_cahce_view_report?.item_data_report).map((itm, inx) => {
                return (
                <p className="itemdata">
                    {itm}
                    </p>
                );
              }): null }    
            <div class="item_bx_wrap_days"><Link to={leverage_browser_url} ><p><button data-id="organic_search_traffic" class="link_rn_rpt_btn performance_data" data-name="Organic Search Traffic">View Report</button></p></Link></div>
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === 'panel4'} onChange={handleChanged('panel4')}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
          <Typography sx={{ width: '33%', flexShrink: 0 }}>
          Minify Javascript
          </Typography>
          <Typography sx={{ color: 'text.secondary' }}>{ErrorCarddata?.unminified_javascript?.result}</Typography>
          <Typography sx={{ color: 'text.secondary',margin:"0px auto" }}>{ErrorCarddata?.unminified_javascript?.status === "success" ?   <img
                src={trueimages}
                className="view_per_img"
                alt="view report icon"

              /> : ErrorCarddata?.unminified_javascript?.status === "danger" ?   <img
              src={failimages}
              className="view_per_img"
              alt="view report icon"

            /> :   <img
            src={warningimages}
            className="view_per_img"
            alt="view report icon"

          />  }</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography className="error_result_data">
          <center> <h3>{ErrorCarddata?.unminified_javascript_view_report?.unminified_javascript_report?.result}</h3></center>
          </Typography>
          <Typography>
          {ErrorCarddata && ErrorCarddata.unminified_javascript_view_report && ErrorCarddata.unminified_javascript_view_report.item_data_report && ErrorCarddata.unminified_javascript_view_report.item_data_report.length > 0 ?
             (ErrorCarddata?.unminified_javascript_view_report?.item_data_report).map((itm, inx) => {
               return (
               <p className="itemdata">
                   {itm}
                   </p>
               );
             }): null }
          
         </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === 'panel5'} onChange={handleChanged('panel5')}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
          <Typography sx={{ width: '33%', flexShrink: 0 }}>
          Minify CSS
          </Typography>
          <Typography sx={{ color: 'text.secondary' }}>{ErrorCarddata?.unminified_css?.result}</Typography>
          <Typography sx={{ color: 'text.secondary',margin:"0px auto" }}>{ErrorCarddata?.unminified_css?.status === "success" ?   <img
                src={trueimages}
                className="view_per_img"
                alt="view report icon"

              /> : ErrorCarddata?.unminified_css?.status === "danger" ?   <img
              src={failimages}
              className="view_per_img"
              alt="view report icon"

            /> :   <img
            src={warningimages}
            className="view_per_img"
            alt="view report icon"

          />  }</Typography>
        </AccordionSummary>
        <AccordionDetails >
        <Typography className="error_result_data">
        <center> <h3> {ErrorCarddata?.unminified_css_score_report?.unminified_css_score_report?.result}  </h3></center>
          </Typography>
          <Typography>
       {ErrorCarddata?.unminified_css_score_report?.unminified_css_score_report?.unminified_css_items}
          </Typography>
          
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === 'panel6'} onChange={handleChanged('panel6')}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
          <Typography sx={{ width: '33%', flexShrink: 0 }}>
          Network Page Load Size
          </Typography>
          <Typography sx={{ color: 'text.secondary' }}>{ErrorCarddata?.total_byte_weight_score_report?.title}</Typography>
          <Typography sx={{ color: 'text.secondary',margin:"0px auto" }}>
          {ErrorCarddata?.total_byte_weight_score_report?.status === "success" ?   <img
                src={trueimages}
                className="view_per_img"
                alt="view report icon"

              /> : ErrorCarddata?.total_byte_weight_score_report?.status === "danger" ?   <img
              src={failimages}
              className="view_per_img"
              alt="view report icon"

            /> :   <img
            src={warningimages}
            className="view_per_img"
            alt="view report icon"

          />  }</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography className="error_result_data">
         <center> <h3>{ErrorCarddata?.total_byte_weight_reports?.result}</h3></center>
          </Typography>
          <Typography style={{width:"100%"}}>
         <center >{ErrorCarddata?.total_byte_weight_reports?.result_data} </center> 
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === 'panel7'} onChange={handleChanged('panel7')}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
          <Typography sx={{ width: '33%', flexShrink: 0 }}>
          Time-to-Interactive
          </Typography>
          <Typography sx={{ color: 'text.secondary' }}>{ErrorCarddata?.interactive_time?.result}</Typography>
          <Typography sx={{ color: 'text.secondary',margin:"0px auto" }}> {ErrorCarddata?.interactive_time?.status === "success" ?   <img
                src={trueimages}
                className="view_per_img"
                alt="view report icon"

              /> : ErrorCarddata?.interactive_time?.status === "danger" ?   <img
              src={failimages}
              className="view_per_img"
              alt="view report icon"

            /> :   <img
            src={warningimages}
            className="view_per_img"
            alt="view report icon"

          />  }</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography className="error_result_data">
         <center> <h3>{ErrorCarddata?.interactive_time_view_report?.display_value}</h3></center>
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === 'panel8'} onChange={handleChanged('panel8')}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
          <Typography sx={{ width: '33%', flexShrink: 0 }}>
          Rendering Block
          </Typography>
          <Typography sx={{ color: 'text.secondary' }}>{ErrorCarddata?.render_blocking?.title ? ErrorCarddata?.render_blocking?.title :ErrorCarddata?.render_blocking?.result  }</Typography>
          <Typography sx={{ color: 'text.secondary',margin:"0px auto" }}>{ErrorCarddata?.render_blocking?.status === "success" ?   <img
                src={trueimages}
                className="view_per_img"
                alt="view report icon"

              /> : ErrorCarddata?.render_blocking?.status === "danger" ?   <img
              src={failimages}
              className="view_per_img"
              alt="view report icon"

            /> :   <img
            src={warningimages}
            className="view_per_img"
            alt="view report icon"

          />  }</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography className="error_result_data">
         <center> <h3>{ErrorCarddata?.render_blocking_view_report?.render_blocking_items} </h3></center>
          </Typography>
          {/* <Typography>
          {ErrorCarddata && ErrorCarddata.render_blocking_view_report && ErrorCarddata.render_blocking_view_report.render_blocking_item_data && ErrorCarddata.render_blocking_view_report.render_blocking_item_data.length > 0 ?
              (ErrorCarddata?.render_blocking_view_report?.render_blocking_item_data).map((itm, inx) => {
                return (
                <li className="itemdata">
                    {itm}
                    </li>
                );
           
              })
              <div class="item_bx_wrap_days"> <Link to={detail_Render_url} ><p><button data-id="organic_search_traffic" class="link_rn_rpt_btn performance_data " data-name="Organic Search Traffic">View Report</button></p></Link></div>
              : null }
           
          </Typography> */}
          <Typography>
  {ErrorCarddata &&
  ErrorCarddata.render_blocking_view_report &&
  ErrorCarddata.render_blocking_view_report.render_blocking_item_data &&
  ErrorCarddata.render_blocking_view_report.render_blocking_item_data.length > 0 ? (
    <>
      {ErrorCarddata.render_blocking_view_report.render_blocking_item_data.map((itm, inx) => (
        <li className="itemdata" key={inx}>
          {itm}
        </li>
      ))}
      <div className="item_bx_wrap_days">
        <Link to={detail_Render_url}>
          <p>
            <button
              data-id="organic_search_traffic"
              className="link_rn_rpt_btn performance_data"
              data-name="Organic Search Traffic"
            >
              View Report
            </button>
          </p>
        </Link>
      </div>
    </>
  ) : null}
</Typography>

        </AccordionDetails>
      </Accordion>
      
     
       </div>

      </div>

            
          </div>
        </div>
        <Dialog
          open={apiopen}
          onClose={apihandleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle>{"Alert!! "}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              {apierrorMsg}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={apihandleClose}>Close</Button>
          </DialogActions>
        </Dialog>

        <Dialog
        open={DetailRunReportPopShow}
        onClose={CloseModalTrigger}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="competitor_test_filter job_date_filter run_report_filter"
      >
        <DialogTitle>{"Choose The Pdf Report Type"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            <div className="cnt_hdr_blw_wrap row">
     
              <div className="run_report_now_modal">
                <div class="main-container">
                  <div class="radio-buttons">
                    <label class="custom-radio">
                      <input
                        type="radio"
                        name="radio"
                        value={"hard"}
                        onClick={(e) => {
                          pdfdown()
                         }}
                      />
                      <span class="radio-btn">
                        <i class="las la-check"></i>
                        <div class="hobbies-icon">
                          <img src={ResetPng} />
                          <h3 class="">Detail Report</h3>
                        </div>
                      </span>
                    </label>
                    <label class="custom-radio">
                      <input
                        type="radio"
                        name="radio"
                        value={"soft"}
                        // checked={SoftRunChecked}
                        onClick={(e) => {
                          pdfsummarydown()
                        }}
                      />
                      <span class="radio-btn">
                        <i class="las la-check"></i>
                        <div class="hobbies-icon">
                          <img src={RestorePng} />
                          <h3 class="">Summary Report</h3>
                        </div>
                      </span>
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </DialogContentText>
        </DialogContent>
      
      
      </Dialog>
       
      </div>
    </div>
  );
};

export default AccessbilityScoreDetails;
   


