import { createSlice } from "@reduxjs/toolkit";

const esoSectionDataSlice=createSlice({
    name:'esodata',
    initialState:{value:'3'},
   
})





export default esoSectionDataSlice.reducer