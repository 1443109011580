import Login_tab from "./login";


function LoginSection(){

    return (
        <>
            
            <div className="parent">
        
            <Login_tab />
            </div>
        </>
       
    );
}
export default LoginSection;