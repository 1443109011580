import { createSlice } from "@reduxjs/toolkit";

const FirstTimeLoginSlice=createSlice({
    name:'firsttimelogin',
    initialState:{value:{eso_user_first_time_login:localStorage.getItem('eso_user_first_time_login')}},
    reducers:{
        SetUserFirstTimeLogin:(state,action)=>{
           localStorage.setItem("eso_user_first_time_login", action.payload.eso_user_first_time_login);
           state.value=action.payload
        }
    }
})


export const {SetUserFirstTimeLogin}=FirstTimeLoginSlice.actions


export default FirstTimeLoginSlice.reducer