import React, { useState, useEffect, useContext } from "react";
import { Link, useParams } from "react-router-dom";
import pdficon from "./../Asset/PDF.png";
import csvicon from "./../Asset/CSV.png";
import axios from "axios";
import LiquidFillGauge from "react-liquid-gauge";
import { color } from "d3-color";
import { useSelector } from "react-redux";
import TabContext from "@mui/lab/TabContext";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { styled, useTheme } from "@mui/material/styles";
import moment from "moment";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { ToastContainer, toast } from 'react-toastify';
import { useNavigate } from "react-router-dom";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import "./accessbilitydesign.css";
import { Commonfun } from './../Commonfun';
import { timezonechange } from './../Commonfun';
import { timezoneval } from './../Commonfun';
import Loader from "../CommonLoader";
import { AppContext } from "../../../Context/Context";

const AccessbilityScoreDetails = () => {
  let querystring = useParams();
  const { isMenuExpanded, setIsMenuExpanded } = useContext(AppContext);
  const ItemId = querystring.id;
  const JobId = querystring.jobid;
  const esodata = useSelector((state) => state);

  const [jobDate, SetjobDate] = useState("");
  const [queryurl, Setqueryurl] = useState("");
  const [loading, setLoading] = useState(false);
  const [apiopen, setapiOpen] = React.useState(false);
  const [apierrorMsg, setapierrorMsg] = useState();
  const navigate = useNavigate();
  const ApiToken = esodata.apiauthkey.value;

  const fillColor = "rgb(255,174,31)";
  const textfillColor = "rgb(255,255,255)";
  const gradientStops = [
    {
      key: "0%",
      stopColor: color(fillColor).darker(0.5).toString(),
      stopOpacity: 1,
      offset: "0%",
    },
    {
      key: "50%",
      stopColor: fillColor,
      stopOpacity: 0.75,
      offset: "50%",
    },
    {
      key: "100%",
      stopColor: color(fillColor).brighter(0.5).toString(),
      stopOpacity: 0.5,
      offset: "100%",
    },
  ];
  const [totalpercecntwords, setTotalpercentwords] = useState("");
  var state = {
    value: totalpercecntwords,
  };

  const [value, setValue] = useState("1");
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const StyledGridOverlay = styled("div")(({ theme }) => ({
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
    "& .ant-empty-img-1": {
      fill: theme.palette.mode === "light" ? "#aeb8c2" : "#262626",
    },
    "& .ant-empty-img-2": {
      fill: theme.palette.mode === "light" ? "#f5f5f7" : "#595959",
    },
    "& .ant-empty-img-3": {
      fill: theme.palette.mode === "light" ? "#dce0e6" : "#434343",
    },
    "& .ant-empty-img-4": {
      fill: theme.palette.mode === "light" ? "#fff" : "#1c1c1c",
    },
    "& .ant-empty-img-5": {
      fillOpacity: theme.palette.mode === "light" ? "0.8" : "0.08",
      fill: theme.palette.mode === "light" ? "#f5f5f5" : "#fff",
    },
  }));
  const MyCustomNoRowsOverlay = () => (
    <StyledGridOverlay>
      <svg
        width="120"
        height="100"
        viewBox="0 0 184 152"
        aria-hidden
        focusable="false"
      >
        <g fill="none" fillRule="evenodd">
          <g transform="translate(24 31.67)">
            <ellipse
              className="ant-empty-img-5"
              cx="67.797"
              cy="106.89"
              rx="67.797"
              ry="12.668"
            />
            <path
              className="ant-empty-img-1"
              d="M122.034 69.674L98.109 40.229c-1.148-1.386-2.826-2.225-4.593-2.225h-51.44c-1.766 0-3.444.839-4.592 2.225L13.56 69.674v15.383h108.475V69.674z"
            />
            <path
              className="ant-empty-img-2"
              d="M33.83 0h67.933a4 4 0 0 1 4 4v93.344a4 4 0 0 1-4 4H33.83a4 4 0 0 1-4-4V4a4 4 0 0 1 4-4z"
            />
            <path
              className="ant-empty-img-3"
              d="M42.678 9.953h50.237a2 2 0 0 1 2 2V36.91a2 2 0 0 1-2 2H42.678a2 2 0 0 1-2-2V11.953a2 2 0 0 1 2-2zM42.94 49.767h49.713a2.262 2.262 0 1 1 0 4.524H42.94a2.262 2.262 0 0 1 0-4.524zM42.94 61.53h49.713a2.262 2.262 0 1 1 0 4.525H42.94a2.262 2.262 0 0 1 0-4.525zM121.813 105.032c-.775 3.071-3.497 5.36-6.735 5.36H20.515c-3.238 0-5.96-2.29-6.734-5.36a7.309 7.309 0 0 1-.222-1.79V69.675h26.318c2.907 0 5.25 2.448 5.25 5.42v.04c0 2.971 2.37 5.37 5.277 5.37h34.785c2.907 0 5.277-2.421 5.277-5.393V75.1c0-2.972 2.343-5.426 5.25-5.426h26.318v33.569c0 .617-.077 1.216-.221 1.789z"
            />
          </g>
          <path
            className="ant-empty-img-3"
            d="M149.121 33.292l-6.83 2.65a1 1 0 0 1-1.317-1.23l1.937-6.207c-2.589-2.944-4.109-6.534-4.109-10.408C138.802 8.102 148.92 0 161.402 0 173.881 0 184 8.102 184 18.097c0 9.995-10.118 18.097-22.599 18.097-4.528 0-8.744-1.066-12.28-2.902z"
          />
          <g className="ant-empty-img-4" transform="translate(149.65 15.383)">
            <ellipse cx="20.654" cy="3.167" rx="2.849" ry="2.815" />
            <path d="M5.698 5.63H0L2.898.704zM9.259.704h4.985V5.63H9.259z" />
          </g>
        </g>
      </svg>
      <Box sx={{ mt: 1 }}>No Data</Box>
    </StyledGridOverlay>
  );

  const columns = [
    {
      field: "code",
      headerName: "Code(s)",
      description: "It  represents the code identifying the specific WCAG guideline",
      minWidth: 350,
      flex: 1,
      disableClickEventBubbling: true,
      headerAlign: "center",
      align: "center",

    },
    {
      field: "technique",
      headerName: "Technique(s)",
      description: "It includes a link to the technique",
      minWidth: 450,
      flex: 1,
      headerAlign: "center",
      align: "center",
      renderCell: (ErrorCarddata) => {
        return (
          (ErrorCarddata?.row?.techniques).toString()
        );
      },
    },
    {
      field: "tag",
      headerName: "Tag",
      description: "Specifies the HTML tag associated with the error",
      minWidth: 100,
      flex: 1,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "msg",
      headerName: "Message ",
      description: "It Indicates the error message",
      minWidth: 350,
      flex: 1,

      headerAlign: "center",
      align: "center",
    },

    {
      field: "element",
      headerName: "Element",
      description: " It shows the HTML element where the error occurred",
      minWidth: 350,
      flex: 1,

      headerAlign: "center",
      align: "center",
    },
  ];
  const columns1 = [
    {
      field: "code",
      headerName: "Code(s)",
      description: "Seo Metrics from site audit report",
      minWidth: 350,
      flex: 1,
      disableClickEventBubbling: true,
      headerAlign: "center",
      align: "center",

    },
    {
      field: "technique",
      headerName: "Technique(s)",
      description: "Number of Occurrences from site audit report",
      minWidth: 450,
      flex: 1,
      headerAlign: "center",
      align: "center",
      renderCell: (WarningsCarddata) => {
        return (
          (WarningsCarddata?.row?.techniques).toString()
        );
      },
    },
    {
      field: "tag",
      headerName: "Tag",
      description: "Number of Occurrences from site audit report",
      minWidth: 100,
      flex: 1,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "msg",
      headerName: "Message ",
      description: "Number of Occurrences from site audit report",
      minWidth: 350,
      flex: 1,

      headerAlign: "center",
      align: "center",
    },

    {
      field: "element",
      headerName: "Element",
      description: "Number of Occurrences from site audit report",
      minWidth: 350,
      flex: 1,

      headerAlign: "center",
      align: "center",
    },
  ];
  const columns2 = [
    {
      field: "code",
      headerName: "Code(s)",
      description: "Seo Metrics from site audit report",
      minWidth: 350,
      flex: 1,
      disableClickEventBubbling: true,
      headerAlign: "center",
      align: "center",

    },
    {
      field: "technique",
      headerName: "Technique(s)",
      description: "Number of Occurrences from site audit report",
      minWidth: 450,
      flex: 1,
      headerAlign: "center",
      align: "center",
      renderCell: (NoticesCarddata) => {
        return (
          (NoticesCarddata?.row?.techniques).toString()
        );
      },
    },
    {
      field: "tag",
      headerName: "Tag",
      description: "Number of Occurrences from site audit report",
      minWidth: 100,
      flex: 1,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "msg",
      headerName: "Message ",
      description: "Number of Occurrences from site audit report",
      minWidth: 350,
      flex: 1,

      headerAlign: "center",
      align: "center",
    },

    {
      field: "element",
      headerName: "Element",
      description: "Number of Occurrences from site audit report",
      minWidth: 350,
      flex: 1,

      headerAlign: "center",
      align: "center",
    },
  ];

  const [ErrorCarddata, SetErrorCarddata] = useState([]);
  const [WarningsCarddata, SetWarningsCarddata] = useState([]);
  const [NoticesCarddata, SetNoticesCarddata] = useState([]);
  const [ErrorCardcount, SetErrorCardcount] = useState("");
  const [WarningCardcount, SetWarningCardcount] = useState("");
  const [NoticeCardcount, SetNoticeCardcount] = useState("");


  const rows = ErrorCarddata ?? [];
  const rows1 = WarningsCarddata ?? [];
  const rows2 = NoticesCarddata ?? [];

  const AccessbilityScoreDetail = async () => {
    const datavalue = await axios.get(

      `${process.env.REACT_APP_API_URL}technical/accessibility_detaildata/?user_domain_id=${esodata.domaindata.value.header_selected}&job_id=${JobId}&id=${ItemId}`, {
      headers: {
        Authorization: `${ApiToken}`,
      },
    }
    ).catch((error) => {
      if (error?.response?.status == 599 || error?.response?.status == 400 || error?.response?.status == 500) {
        setapiOpen(true);
        setapierrorMsg(error?.response?.data?.error)
      }
      else if (error?.code == "ERR_NETWORK" || error?.response?.status == 404) {
        navigate('/errorpages')
      }
      // console.log("kannan",error)
    });
    console.log("datavalue", datavalue)
    if (datavalue?.data) {
      const dataval = datavalue?.data?.acc_data[0]?.error_data;
      const newData = dataval.map((obj, index) => {
        return { ...obj, nid: index + 1 };
      });

      const dataval1 = datavalue?.data?.acc_data[0]?.warning_data;
      const newData1 = dataval1.map((obj, index) => {
        return { ...obj, sid: index + 1 };
      });

      const dataval2 = datavalue?.data?.acc_data[0]?.notice_data;
      const newData2 = dataval2.map((obj, index) => {
        return { ...obj, tid: index + 1 };
      });

      SetErrorCardcount(newData?.length)
      SetWarningCardcount(newData1?.length)
      SetNoticeCardcount(newData2?.length)
      SetErrorCarddata(newData)
      SetWarningsCarddata(newData1)
      SetNoticesCarddata(newData2)
      // SetjobDate(moment(datavalue?.data?.job_date).format("MMMM DD,YYYY HH:mm"));
      if(datavalue?.data?.job_date) 
        SetjobDate(moment(datavalue?.data?.job_date).format("MMMM DD,YYYY HH:mm"))    
        else
        SetjobDate(moment(datavalue?.data?.job_date).format("MMMM DD,YYYY")) 
      Setqueryurl(datavalue?.data?.acc_data[0]?.link__url)
    }


  };
  function generateRandom() {
    var length = 8,
      charset = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789",
      retVal = "";
    for (var i = 0, n = charset.length; i < length; ++i) {
      retVal += charset.charAt(Math.floor(Math.random() * n));
    }
    return retVal;
  }
  useEffect(() => {
    AccessbilityScoreDetail();
  }, []);
  const pdfdown = async () => {
    setLoading(true);
    if (value == 1)
      var typedata = "ERRORS";
    else if (value == 2)
      var typedata = "WARNINGS";
    else if (value == 3)
      var typedata = "NOTICES";
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}technical/accessibility_detaildata/?user_domain_id=${esodata.domaindata.value.header_selected}&job_id=${JobId}&id=${ItemId}&type=${typedata}&formats=pdf&timezone=${timezoneval()}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/pdf',
          Authorization: `${ApiToken}`,
        },
      });
      // Check if the request was successful (status code 200)
      if (response.ok) {
        // Convert the response to a blob
        const blob = await response.blob();

        // Create a temporary anchor element
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        var filedata = await Commonfun("AccessibilityDetail", "pdf", ApiToken, esodata.domaindata.value.header_selected);
        // return false;
        link.download = filedata;
        link.click();

        // Remove the anchor element
        window.URL.revokeObjectURL(link.href);
      } else {
        toast.error("No Data", {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        console.error('Failed to fetch PDF:', response.status, response.statusText);
      }
    } catch (error) {
      toast.error("No Data", {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      console.error('Error during PDF download:', error);
    }
    setLoading(false);

  }
  const csvdown = async () => {
    setLoading(true);
    if (value == 1)
      var typedata = "ERRORS";
    else if (value == 2)
      var typedata = "WARNINGS";
    else if (value == 3)
      var typedata = "NOTICES";
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}technical/accessibility_detaildata/?user_domain_id=${esodata.domaindata.value.header_selected}&job_id=${JobId}&id=${ItemId}&type=${typedata}&formats=csv&timezone=${timezoneval()}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/pdf',
          Authorization: `${ApiToken}`,
        },
      });
      // Check if the request was successful (status code 200)
      if (response.ok) {
        // Convert the response to a blob
        const blob = await response.blob();

        // Create a temporary anchor element
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        var filedata = await Commonfun("AccessibilityDetail", "csv", ApiToken, esodata.domaindata.value.header_selected);
        // return false;
        link.download = filedata;
        link.click();

        // Remove the anchor element
        window.URL.revokeObjectURL(link.href);
      } else {
        toast.error("No Data", {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        console.error('Failed to fetch PDF:', response.status, response.statusText);
      }
    } catch (error) {
      toast.error("No Data", {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      console.error('Error during PDF download:', error);
    }
    setLoading(false);
  }
  const apihandleClose = () => {
    setapiOpen(false);
  };
  return (
    <div className={`over_all_container ${isMenuExpanded ? "blur overflow-hidden" : ""}`}>
      {loading === true ? <Loader /> : null}

      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <div className="cnt_audit_outer_wrap">
        <div className="cnt_hdr_top_wrap acc-scr-dtl">
          <div className="cnt_hdr_top_lft_wrap">
            <h2>Accessibility Score Detail Report For</h2>
            <p><b>{queryurl}</b></p>
            {/* <p>as of {jobDate}</p> */}
            <p>as of {timezonechange(jobDate)}  <span style={{ fontWeight: 'bold' }}> - {timezoneval()} </span></p>

          </div>
          <div className="cnt_hdr_top_rgt_wrap">
            <img src={pdficon} alt="pdf icon" onClick={pdfdown} />
            <img src={csvicon} alt="csv icon" onClick={csvdown} />
          </div>
        </div>

        <div className="cnt_hdr_content">
          <div className="box_wrap">
            <div>
              <h3></h3>
              <p></p>
            </div>
            {/* 
            <div>
              <div className="accessbility_report_statistics">
                <h3>Page Statistics</h3>
              </div>

              <div className="page_statistics_row">
                <div className="col1">
                  <div className="data_row">
                    <span>
                      <span className="icon">
                        <LinkIcon />
                      </span>
                      Url :{" "}
                    </span>
                    <span >
                      https://squizlabs.github.io/HTML_CodeSniffer/Standards/WCAG2/
                    </span>
                  </div>
                  <div className="data_row">
                    <span>
                    <span className="icon">
                        <OpenInBrowserTwoToneIcon />
                      </span>
                    
                      Browser :{" "}
                    </span>
                    <span>Chrome 117.0.0.0</span>
                  </div>
                  <div className="data_row">
                    <span>
                    <span className="icon">
                        <ComputerTwoToneIcon />
                      </span>
                 
                      Device :{" "}
                    </span>
                    <span>Laptop/Desktop</span>
                  </div>
                </div>
                <div className="col2">
                  <div className="data_row">
                    <span>
                    <span className="icon">
                        <TocTwoToneIcon />
                      </span>
                   
                      Standard :{" "}
                    </span>
                    <span>WCAG2AA</span>
                  </div>
                  <div className="data_row">
                    <span>
                    <span className="icon">
                        <OpenWithTwoToneIcon />
                      </span>
                     
                      Browser size :{" "}
                    </span>
                    <span>1280 X 720</span>
                  </div>
                  <div className="data_row">
                    <span>
                    <span className="icon">
                        <AccessTimeFilledTwoToneIcon />
                      </span>
                 
                      Timestamp :{" "}
                    </span>
                    <span>28/9/2023 12:20:26</span>
                  </div>
                </div>
              </div>
            </div> */}

            <div className="cnt_hdr_content_box_outer acc-dt-rpt">
              <div className="plagiarisam_missing_waring_wrap accessbility_score_warning_wrap error_content_count" style={{ backgroundColor: "#f30000", color: "#000000" }}>
                <p style={{ color: "#000000" }}>{ErrorCardcount}</p>
                <p style={{ color: "#000000" }}>Errors</p>
              </div>
              <div className="plagiarisam_missing_waring_wrap accessbility_score_warning_wrap warning_content_count" style={{ backgroundColor: "#fdfe4e", color: "#000000" }}>
                <p style={{ color: "#000000" }}>{WarningCardcount}</p>
                <p style={{ color: "#000000" }}>Warnings</p>
              </div>
              <div className="plagiarisam_missing_waring_wrap accessbility_score_warning_wrap notices_content_count">
                <p>{NoticeCardcount}</p>
                <p>Notices</p>
              </div>
            </div>
          </div>
        </div>

        <div className="tabs_outer_wrap tabs_outer_wrap_tab_btm_rem">
          <TabContext value={value}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>

              <TabList
                onChange={handleChange}
                aria-label="lab API tabs example"
              >
                <Tab label="Errors" value="1" />
                <Tab label="Warnings" value="2" />
                <Tab label="Notices" value="3" />
              </TabList>
            </Box>
            <TabPanel value="1">
              <div className="data_table accessbledetail" style={{ width: "100%" }}>
                <div style={{ height: 750, width: "100%" }}>
                  <DataGrid
                    sx={{
                      "&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell": {
                        py: "8px",
                      },
                      "&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell": {
                        py: "15px",
                      },
                      "&.MuiDataGrid-root--densityComfortable .MuiDataGrid-cell":
                      {
                        py: "22px",
                      },
                      "& .MuiDataGrid-columnHeaderTitle": {
                        whiteSpace: "normal",
                        lineHeight: "normal",
                      },
                      "&, [class^=MuiDataGrid]": { border: "none" },
                      "& .MuiDataGrid-columnSeparator--sideRight": {
                        display: "none"
                      },
                    }}
                    autoHeight
                    getRowHeight={() => "auto"}
                    // getRowId={(row) =>  generateRandom()}
                    getRowId={(row) => row.nid}
                    slots={{
                      noRowsOverlay: MyCustomNoRowsOverlay,
                      toolbar: GridToolbar
                    }}
                    slotProps={{
                      toolbar: {

                        showQuickFilter: true,

                      },
                    }}
                    rows={rows}
                    columns={columns}
                    initialState={{
                      pagination: {
                        paginationModel: {
                          pageSize: 5,
                        },
                      },
                    }}
                    pageSizeOptions={[5, 10, 25]}
                    disableColumnFilter
                    disableColumnSelector
                    disableDensitySelector
                  />
                </div>
              </div>
            </TabPanel>
            <TabPanel value="2">
              <div className="data_table accessbledetail" style={{ width: "100%" }}>
                <div style={{ height: 750, width: "100%" }}>
                  <DataGrid
                    sx={{
                      "&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell": {
                        py: "8px",
                      },
                      "&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell": {
                        py: "15px",
                      },
                      "&.MuiDataGrid-root--densityComfortable .MuiDataGrid-cell":
                      {
                        py: "22px",
                      },
                      "& .MuiDataGrid-columnHeaderTitle": {
                        whiteSpace: "normal",
                        lineHeight: "normal",
                      },
                      "&, [class^=MuiDataGrid]": { border: "none" },
                      "& .MuiDataGrid-columnSeparator--sideRight": {
                        display: "none"
                      },
                    }}
                    autoHeight
                    getRowHeight={() => "auto"}
                    // getRowId={(row) =>  generateRandom()}
                    getRowId={(row) => row.sid}
                    slots={{
                      noRowsOverlay: MyCustomNoRowsOverlay,
                      toolbar: GridToolbar
                    }}
                    slotProps={{
                      toolbar: {

                        showQuickFilter: true,

                      },
                    }}
                    rows={rows1}
                    columns={columns1}
                    initialState={{
                      pagination: {
                        paginationModel: {
                          pageSize: 5,
                        },
                      },
                    }}
                    pageSizeOptions={[5, 10, 25]}
                    disableColumnFilter
                    disableColumnSelector
                    disableDensitySelector
                  />
                </div>
              </div>
            </TabPanel>
            <TabPanel value="3">
              <div className="data_table accessbledetail" style={{ width: "100%" }}>
                <div style={{ height: 750, width: "100%" }}>
                  <DataGrid
                    sx={{
                      "&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell": {
                        py: "8px",
                      },
                      "&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell": {
                        py: "15px",
                      },
                      "&.MuiDataGrid-root--densityComfortable .MuiDataGrid-cell":
                      {
                        py: "22px",
                      },
                      "& .MuiDataGrid-columnHeaderTitle": {
                        whiteSpace: "normal",
                        lineHeight: "normal",
                      },
                      "&, [class^=MuiDataGrid]": { border: "none" },
                      "& .MuiDataGrid-columnSeparator--sideRight": {
                        display: "none"
                      },
                    }}
                    autoHeight
                    getRowHeight={() => "auto"}
                    // getRowId={(row) =>  generateRandom()}
                    getRowId={(row) => row.tid}
                    slots={{
                      noRowsOverlay: MyCustomNoRowsOverlay,
                      toolbar: GridToolbar
                    }}
                    slotProps={{
                      toolbar: {

                        showQuickFilter: true,

                      },
                    }}
                    rows={rows2}
                    columns={columns2}
                    initialState={{
                      pagination: {
                        paginationModel: {
                          pageSize: 5,
                        },
                      },
                    }}
                    pageSizeOptions={[5, 10, 25]}
                    disableColumnFilter
                    disableColumnSelector
                    disableDensitySelector
                  />
                </div>
              </div>
            </TabPanel>
          </TabContext>
        </div>
        <Dialog
          open={apiopen}
          onClose={apihandleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle>{"Alert!! "}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              {apierrorMsg}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={apihandleClose}>Close</Button>
          </DialogActions>
        </Dialog>
      </div>
    </div>
  );
};

export default AccessbilityScoreDetails;
