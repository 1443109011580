import React, { createContext, useState } from 'react';

export const AppContext = createContext();


export const AppProvider = ({ children }) => {
    const [topactionnotify, setTopactionnotify] = useState(0);
    const [isMenuExpanded, setIsMenuExpanded] = useState(false);
  
    return (
      <AppContext.Provider value={{ topactionnotify, setTopactionnotify, isMenuExpanded, setIsMenuExpanded }}>
        {children}
      </AppContext.Provider>
    );
  };
  