import { createSlice } from "@reduxjs/toolkit";

const UserTokenSlice=createSlice({
    name:'usertoken',
    initialState:{value:{eso_user_token:localStorage.getItem('eso_user_token')}},
    reducers:{
        SetUserToken:(state,action)=>{
           localStorage.setItem("eso_user_token", action.payload.eso_user_token);
           state.value=action.payload
        }
    }
})


export const {SetUserToken}=UserTokenSlice.actions


export default UserTokenSlice.reducer