import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
const APITOKEN=localStorage.getItem('eso_auth_token');

const DomainListSlice=createSlice({
    name:'domainlist',
    initialState:{value:[]},
    reducers:{
       
    
    },
    extraReducers: (builder)=>{
      builder.addCase(GetListOfDomains.fulfilled,(state,action)=>{
        state.value=action.payload
      })
    }
})





export const GetListOfDomains=createAsyncThunk('eso/list-of-domains',async ()=>{
  const result=await axios.get(
    `${process.env.REACT_APP_API_URL}domain/get_user_domains/`,{
      headers: {
        Authorization: `${APITOKEN}`,
      },
    }
  ) 

  return result.data

})


export default DomainListSlice.reducer