import React, { useState, useEffect } from "react";
import axios from "axios";
import "./Logindetail.css";
import { Link, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import Select from "react-dropdown-select";
import { useNavigate } from "react-router-dom";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import QuestionMarkIcon from "@mui/icons-material/QuestionMark";
import Rightsidebar from "../Rightsidebar/Rightsidebar";

function Userdata (){
  const [apiOpen, setApiOpen] = useState(false);
  const [apiErrorMsg, setApiErrorMsg] = useState("");
  const [toggle, setToggle] = useState(false);
  const [faqData,setfaqData]=useState("2")
  const [initialfaqDateval,SetInitialfaqDateval] = useState([]);
  const [loading, setLoading] = useState(false);
  const esodata = useSelector((state) => state);
  const [JobId, SetJobId] = useState("");
  const [Countryoptions, SetCountryoptions] = useState([]);
  const [Stateoptions, SetStateoptions] = useState([]);
  const [CityOptions, SetCityOptions] = useState([]);
  const [Country, SetCountry] = useState("");
  const [State, SetState] = useState("");
  const [City, SetCity] = useState("");
  const [CountryError,SetCountryError] = useState("");
  const [StateError,SetStateError] = useState("");
  const [CityError,SetCityError] = useState("");
  const navigate = useNavigate();
  const [apiopen, setapiOpen] = React.useState(false);
  const [apierrorMsg,setapierrorMsg]=useState();

  const apihandleClose = () => {
    setapiOpen(false);
  };

  const ApiToken = esodata.apiauthkey.value;

  useEffect(() => {
    callUserdataApi();
  }, []);
  const faqdatafun = async(menubutval="") => {
    var datavar=`${process.env.REACT_APP_API_URL}domain/frequently_asked_questions/?menu=50`
  
    try {
      const doaminsectiondatavalue = await axios.get(
        datavar,
        {
          headers: {
            Authorization: `${ApiToken}`,
          },
        }
      ).catch((error) => {
        if(error?.response?.status==599 || error?.response?.status==400 || error?.response?.status==500){
          setapiOpen(true);
          setapierrorMsg(error?.response?.data?.error)
        }
        else if(error?.code =="ERR_NETWORK" ||  error?.response?.status==404 ){
          navigate('/errorpages')    
        }
      });
      if (doaminsectiondatavalue?.data) {
        SetInitialfaqDateval(doaminsectiondatavalue?.data?.faqs)
      }
    } catch (err) {}
  }
  useEffect(() => {
    faqdatafun()
  }, []);
  const handleToggle = () => {
    if(faqData!=1){
      setfaqData(1)
    }
    else{
      setfaqData(2)
    }
        setToggle((pre) => !pre);
  };
  const finalreportNow = async () => {
    setLoading(true);
    SetCountryError("")
    SetStateError("")
    SetCityError("")
    if(Country ===""){
      SetCountryError("Select Country")
      return false;
    }  
    if(State === ""){
      SetStateError("Select State")
      return false;
    }
    if(City === ""){
      SetCityError("Select City")
      return false;
    }
    if(Country !=="" && State !=="" && City !=="" ){
      SetCityError("")
      setLoading(true);
      try{
        const userbasicData = await axios.get(
          `${process.env.REACT_APP_API_URL}accounts/save_user_location/?country_id=${Country}&state_id=${State}&city_id=${City.value}`,
          {
            headers: {
              Authorization: `${ApiToken}`,
            },
          }
        ).catch((error) => {
          if(error?.response?.status==599 || error?.response?.status==500 || error?.response?.status==400  || error?.response?.status==404){
            setapiOpen(true);
            setapierrorMsg(error?.response?.data?.error)
          }
          else if(error?.code =="ERR_NETWORK"){
            navigate('/errorpages')    
          }
          // console.log("kannan",error)
        });
 if(userbasicData?.status==200){
  if(userbasicData?.data?.on_boarding_status==1){
    navigate('/')
  }
  else{
    navigate('/Domaindata')
  }
 }
 else{

 }

      }
      catch(err){}
    }
    setLoading(false);
  };
  const SetCountryHandle = async (value) => {
    SetStateoptions([]);
    if (value) {
      SetCountry(value.value);
      try {
        const statedatavalue = await axios.get(
          `${process.env.REACT_APP_API_URL}keywords/get_state_by_country/?country_id=${value.value}`,
          {
            headers: {
              Authorization: `${ApiToken}`,
            },
          }
        ).catch((error) => {
          if(error?.response?.status==599 || error?.response?.status==500 || error?.response?.status==400  || error?.response?.status==404){
            setapiOpen(true);
            setapierrorMsg(error?.response?.data?.error)
          }
          else if(error?.code =="ERR_NETWORK"){
            navigate('/errorpages')    
          }
          // console.log("kannan",error)
        });
        if  (statedatavalue?.data) {
          if (statedatavalue?.data?.states) {
            var Sections = [];
            statedatavalue.data.states.forEach((element) => {
              Sections.push({
                label: element.state_name,
                value: element.id,
              });
            });
            SetStateoptions(Sections);
          }
        }
      } catch (err) {
        console.log(err);
      }
    }
  };
  const SetStateHandle = async (value) => {
    SetCityOptions([]);
    if (value) {
      SetState(value.value)
      const citydatavalue = await axios.get(
        `${process.env.REACT_APP_API_URL}keywords/get_cities_by_state/?state_id=${value.value}`,
        {
          headers: {
            Authorization: `${ApiToken}`,
          },
        }
      ).catch((error) => {
        if(error?.response?.status==599 || error?.response?.status==500 || error?.response?.status==400  || error?.response?.status==404){
          setapiOpen(true);
          setapierrorMsg(error?.response?.data?.error)
        }
        else if(error?.code =="ERR_NETWORK"){
          navigate('/errorpages')    
        }
        // console.log("kannan",error)
      });
      if (citydatavalue?.data) {
        if (citydatavalue?.data?.cities) {
          var Sections = [];
          citydatavalue.data.cities.forEach((element) => {
            Sections.push({
              label: element.city_name,
              value: element.id,
            });
          });
          SetCityOptions(Sections);
        }
      }
    }
  };
  const callUserdataApi = async (jobid = "") => {
    setLoading(true);
    try {
      const countrydatavalue = await axios.get(
        `${process.env.REACT_APP_API_URL}keywords/get_countries/`,
        {
          headers: {
            Authorization: `${ApiToken}`,
          },
        }
      ).catch((error) => {
        if(error?.response?.status==599 || error?.response?.status==500 || error?.response?.status==400  || error?.response?.status==404){
          setapiOpen(true);
          setapierrorMsg(error?.response?.data?.error)
        }
        else if(error?.code =="ERR_NETWORK"){
          navigate('/errorpages')    
        }
        // console.log("kannan",error)
      });
      if (countrydatavalue?.data) {
        if (countrydatavalue?.data) {
          var Sections = [];
          countrydatavalue.data.forEach((element) => {
            Sections.push({ label: element.name, value: element.id });
          });
          SetCountryoptions(Sections);
        }
      }
    } catch (err) {}
    setLoading(false);
  };
    return (
      <div className="userdata addrs">
        <div className="cnt_hdr_top_rgt_wrap" style={{display:"flex" , justifyContent:"flex-end"}}>
        <div className={`App ${faqData=="1"?'faq_move_class':""}`} style={{padding:"20px"}} >
            <button onClick={handleToggle} className="faq_class"  title="Frequently Asked Question" style={{padding:"10px"}}>
            <QuestionMarkIcon />
              
            </button>
           {toggle && <Rightsidebar close={() => setToggle(false)} initialfaqDateval={initialfaqDateval} />} 
           
          </div>
        
        </div>
        <h1>User Data</h1>
        <div className="outer-container"
    style={{
      width: "calc(100% - 60px)",
      marginRight: "60px",
      marginLeft: "auto",
      marginRight: "auto",
    }}
  >
        <div className="userdata_inner">
        <p>Tell us about you...</p>    
        <form className='frm_container'>
        <div className="form-group user_country_data">
          <label for="country" style={{display:"flex"}}>Country:</label>
          <div className="form-inner-data">
            <Select
            options={Countryoptions}
                  onChange={(e) => {
                    SetCountryHandle(e[0]);
                  }}
            />
            <span className="error-value" style={{display:"flex"}}>{CountryError}</span>
          </div>
        </div>
        <div class="form-group user_country_data user_state">
          <label for="state"  style={{display:"flex"}}>State:</label>    
          <div className="form-inner-data">
          <Select
                  options={Stateoptions}
                  onChange={(e) => {
                    SetStateHandle(e[0]);
                  }}
                />
                <span className="error-value" style={{display:"flex"}}>{StateError}</span>
          </div>
        </div>
        <div class="form-group user_country_data user_city">   
            <label for="city"  style={{display:"flex"}}>City:</label>    
            <div className="form-inner-data">
            <Select
                  options={CityOptions}
                  onChange={(e) => {
                    SetCity(e[0]);
                  }}
                />
                <span className="error-value" style={{display:"flex"}}>{CityError}</span>
            </div>
         </div>   
         <div className="form_next div_next_button">      
            <button className="nextbutton"  onClick={finalreportNow} type="button">Next</button>
         </div>   
        </form>
        </div>
        </div>
        <Dialog
          open={apiopen}
          onClose={apihandleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle>{"Alert!! "}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              {apierrorMsg}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={apihandleClose}>Close</Button>
          </DialogActions>
        </Dialog>
      </div>   

    );
}
export default Userdata;