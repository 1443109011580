import React, { useState, useRef,useEffect, useContext} from 'react';
import "./login.css";
import Logo from "../NavBar/Asset/New_Assert/ESO_Logo.png";
import Eso_cnfrm_mail_img from './Asset/On_Board/confirmMail.gif';
import axios from "axios";
import Loader from "../NavBar/CommonLoader";
import { useSelector } from "react-redux";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Link, useParams } from "react-router-dom";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { Helmet } from 'react-helmet';
import { useNavigate } from "react-router-dom";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button"
import { useDispatch } from "react-redux";
import { SetUserToken } from "../../Store/UserTokenSlice";
import { SetAuthToken } from "../../Store/ApiAuthKey";
import { AppContext } from "../../Context/Context";

const ChangePassword=()=>{
  const {isMenuExpanded, setIsMenuExpanded} = useContext(AppContext);

  const dispatch = useDispatch();

  const strongPasswordRegex = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;

  function testPasswordStrength(password) {
    return strongPasswordRegex.test(password);
  }


  let querystring = useParams();
  let uid = querystring.uid;
  let utoken = querystring.utoken;
  const navigate = useNavigate();
    const newpassref= useRef(null);
    const cnewpassref= useRef(null);
    const oldpassref= useRef(null);
    const [loading, setLoading] = useState(false);
    const esodata = useSelector((state) => state);
    const ApiToken = esodata.apiauthkey.value;
    const [apiopen, setapiOpen] = React.useState(false);
    const [apierrorMsg,setapierrorMsg]=useState();

    const [errors, setErrors] = useState({
      newpass: '',
      cnewpass: '',
      });
    const [formData, setFormData] = useState({
      newpass:'',
      cnewpass: '',
      });
      const SmthandleChangeotp = (e) => {

 
        const { name, value } = e.target;

        console.log('nameee',name)
        console.log('value',value)
        setFormData({
          ...formData,
          [name]: value,
        });
      
        // Clear the specific field error when the user starts typing
        setErrors({
          ...errors,
          [name]: '',
        });
      };
    const otp_valid = async(e) => {
        e.preventDefault();

 
        const { newpass,cnewpass,oldpass} = formData;
 
        const newErrors = {};
        var ErrorCheck=0;

        if (!oldpass || oldpass.length === 0 || oldpass===undefined) {
          newErrors.oldpass = "Please enter your old password.";
          ErrorCheck=1;
   
        oldpassref.current.focus();
      } 
    
        if (!newpass || newpass.length === 0 || newpass===undefined) {
            newErrors.newpass = "Please enter your new password";
            ErrorCheck=1;
     
          newpassref.current.focus();
        }
        
        if(newpass.length>0)
        {
          if(testPasswordStrength(newpass)===false)
          {
            newErrors.newpass = "Password does not meet the criteria. It should contain at least one uppercase letter, one digit, and one special character (@, $, !, %, *, ?, &), and be at least 8 characters long";
            ErrorCheck=1;
     
            newpassref.current.focus();

          }
        }

        

        if (!cnewpass || cnewpass.length === 0 || cnewpass===undefined) {
          newErrors.cnewpass = "Please confirm your new password";
          ErrorCheck=1;
        cnewpassref.current.focus();
      } 


     if(newpass!==undefined && cnewpass!==undefined)
     {

      if(newpass.length>0 && cnewpass.length>0)
      {

        if (newpass !==cnewpass) {
          newErrors.cnewpass = "Your passwords does not match!";
          ErrorCheck=1;
   
          cnewpassref.current.focus();
         } 

      }
     }
   
      setErrors(newErrors);

      if(ErrorCheck===0)
      {



        try{


       setLoading(true)
       
        const Response = await axios.post(
          `${process.env.REACT_APP_API_URL}accounts/password_change/`,
          {
            old_password: oldpass,
            new_password: newpass,
            confirm_password: cnewpass
          },
          {
            headers: {
              Authorization: `${ApiToken}`,
            },
          }       
        ).catch((error) => {
          setLoading(false)
          console.log(error)
          if(error?.response?.status==599 || error?.response?.status==400 || error?.response?.status==500){
            setapiOpen(true);
            setapierrorMsg(error?.response?.data?.non_field_errors[0])
          }
          else if(error?.code =="ERR_NETWORK"){
            navigate('/errorpages')    
          }
          // console.log("kannan",error)
        });

        if(Response?.data)
        {
          if(Response?.data?.status==='success')
          {
            setLoading(false)
            setFormData({})
            var inputElement = document.querySelector('[name="oldpass"]');
            inputElement.value=''
            var inputElement = document.querySelector('[name="newpass"]');
            inputElement.value=''
            var inputElement = document.querySelector('[name="cnewpass"]');
            inputElement.value=''
          
            toast.success('You have successfully changed your password.', {
              position: "top-center",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
              });
              localStorage.setItem("eso_auth_token", "");
              localStorage.setItem("eso_user_token", "");
              dispatch(SetUserToken({ eso_user_token: "" }));
              dispatch(SetAuthToken({ eso_auth_token: "" }));
              localStorage.clear()
              navigate('/login')   
          }
        }

        }catch(err)
        {

          var valuesForKey = err.response.data.non_field_errors;
       

          valuesForKey=valuesForKey.join(',')
         
          setLoading(false)
          toast.error(valuesForKey, {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            });


          
        }

 
      }





      };
      /**timer start */
      const [timeRemaining, setTimeRemaining] = useState(180); // 3 minutes in seconds

      useEffect(() => {
        const countdownInterval = setInterval(() => {
          if (timeRemaining > 0) {
            setTimeRemaining(prevTime => prevTime - 1);
          } else {
            clearInterval(countdownInterval);
            // Handle expiration, e.g., show a message or trigger an action
            console.log('Time has expired');
          }
        }, 1000);
    
        // Clean up interval on component unmount
        return () => clearInterval(countdownInterval);
      }, [timeRemaining]);
    
      const formatTime = (time) => {
        const minutes = Math.floor(time / 60);
        const seconds = time % 60;
        return `${minutes}m ${seconds}s`;
      };
      /*timer code end */
      const apihandleClose = () => {
        setapiOpen(false);
      };

      //password validation

      const [showPasswordold,SetshowPasswordold]=useState(false)

      const handleTogglePasswordold=()=>{
        SetshowPasswordold(!showPasswordold)
      }

      const [showPasswordnew,SetshowPasswordnew]=useState(false)

      const handleTogglePasswordnew=()=>{
        SetshowPasswordnew(!showPasswordnew)
      }

      const [showPasswordcnew,SetshowPasswordcnew]=useState(false)

      const handleTogglePasswordcnew=()=>{
        SetshowPasswordcnew(!showPasswordcnew)
      }




    return (
        <div className={`over_all_container ${isMenuExpanded ? "blur overflow-hidden" : ""}`}>
              <Helmet>
                <meta charSet="utf-8" />
                <title>Change Password</title>
            </Helmet>
           {loading === true ? <Loader /> : null}
    <ToastContainer
position="top-center"
autoClose={5000}
hideProgressBar={false}
newestOnTop={false}
closeOnClick
rtl={false}
pauseOnFocusLoss
draggable
pauseOnHover
theme="light"
/>
<div className="outer-container"
    style={{
      width: "calc(100% - 60px)",
      marginRight: "60px",
      marginLeft: "auto",
      marginRight: "auto",
    }}
  >
            <div className="rest_scs_outer_container confirm_sec_outer_container ">
               <div className="rest_scs_ins_container">
               <div className ="Eso_cnfrm_mail_outer_wrap">
                <img className="Eso_logo" src={Logo} alt="logo" />             
                <img className="Eso_rst_img" src={Eso_cnfrm_mail_img} alt="animation gif" />
                </div>
                <h1 className="scs_tt">Change Password</h1>
                <div className="rest_inst_wrap">
                <p>Enter your passwords here</p>
                <form onSubmit={otp_valid} className='frm_container' method="post">



                <div class="form-group email_grp change_password_eye" style={{marginBottom:'30px'}}>
                    <input type={showPasswordold ? "text" : "password"} id="otppas" name="oldpass" placeholder='Enter your old password' value={formData.oldpass} onChange={SmthandleChangeotp} ref={oldpassref}/>
                    <button
                    type="button"
                    className="toggle-password"
                    onClick={handleTogglePasswordold}
                  >
                    {showPasswordold ? (
                      <FaEye className="FaEy" />
                    ) : (
                      <FaEyeSlash className="FaEyeSlash" />
                    )}
                  </button>
                    <div className="frm_error">{errors.oldpass}</div>
                </div>


                <div class="form-group email_grp change_password_eye" style={{marginBottom:'30px'}}>
                    <input type={showPasswordnew ? "text" : "password"} id="otppas" name="newpass" placeholder='Enter your new password' value={formData.newpass} onChange={SmthandleChangeotp} ref={newpassref}/>

                    <button
                    type="button"
                    className="toggle-password"
                    onClick={handleTogglePasswordnew}
                  >
                    {showPasswordnew ? (
                      <FaEye className="FaEy" />
                    ) : (
                      <FaEyeSlash className="FaEyeSlash" />
                    )}
                  </button>
                    <div className="frm_error">{errors.newpass}</div>
                </div>

                <div class="form-group email_grp change_password_eye">
                    <input type={showPasswordcnew ? "text" : "password"} id="otppas" name="cnewpass" placeholder='Confirm your new password' value={formData.cnewpass} onChange={SmthandleChangeotp} ref={cnewpassref}/>

                    <button
                    type="button"
                    className="toggle-password"
                    onClick={handleTogglePasswordcnew}
                  >
                    {showPasswordcnew ? (
                      <FaEye className="FaEy" />
                    ) : (
                      <FaEyeSlash className="FaEyeSlash" />
                    )}
                  </button>
                    <div className="frm_error">{errors.cnewpass}</div>
                </div>
              
                    <button type="submit" className='sbt_btn'>Change Password</button>
                </form>
                </div>
                <div className='cnfrm'>
        
               </div>
               </div>

            </div>
            </div>
            <Dialog
          open={apiopen}
          onClose={apihandleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle>{"Alert!! "}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              {apierrorMsg}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={apihandleClose}>Close</Button>
          </DialogActions>
        </Dialog>
        </div>
       
    );
}
export default ChangePassword;