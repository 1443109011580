import { createSlice,createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const APITOKEN=localStorage.getItem('eso_auth_token');

const CompetitorDomainIdslice=createSlice({
    name:'competitior_domain_id',
    initialState:{value:[]},
    reducers:{
      
    },
    extraReducers: (builder)=>{
        builder.addCase(GetCompetitorJobIds.fulfilled,(state,action)=>{
              
         
    
    
          
           
            state.value=action.payload
   
        })
      }
    
})

export const GetCompetitorJobIds=createAsyncThunk('eso/list-of-competitior_domain_id',async (Domainid='')=>{
    const result=await axios.get(


        
      ''+process.env.REACT_APP_API_URL+'domain/get_competitor_domains/?user_domain_id='+Domainid+'',{
        headers: {
          Authorization: `${APITOKEN}`,
        }, 
      }
    )


   

    var JobArray=[];

    if(result.data.table.competitors)
    {
        result.data.table.competitors.forEach(element => {

     

            JobArray.push({ label: element.competitor_name, value: element.id });

       
            
        });
    }

    return JobArray

 

  
  })

export default CompetitorDomainIdslice.reducer