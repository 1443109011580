import React, { useState,useEffect } from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import ResetPng from "../../Icons/reset.png";
import RestorePng from "../../Icons/restore.png";
import axios from "axios";
import { useSelector } from "react-redux";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Loader from "./CommonLoader";
import { useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";

const RunReportNow = (props) => {
  const esodata = useSelector((state) => state);
  const ApiToken = esodata.apiauthkey.value;
  const [loading,Setloading]=useState(false)
  const [apiopen, setapiOpen] = React.useState(false);
  const [apierrorMsg,setapierrorMsg]=useState();
  const navigate = useNavigate();

  const RunReportTrigger = () => {

    var RunType=0;
   
    if(SoftRunChecked==true)
    {
        RunType=1
    }
    else if (HardRunChecked==true)
    {
        RunType=0
    }
    
    RunReportFunction(props.RunReportType,RunType)
    CloseModalTrigger()
  };
  const CloseModalTrigger = () => {
    props.SetRunReportPopShow(false);
  };

  const [HardRunChecked, SetHardRunChecked] = useState(false);
  const [SoftRunChecked, SetSoftRunChecked] = useState(true);

  const SetRunType = (e) => {
    SetHardRunChecked(false);
    SetSoftRunChecked(false);

    if (e.target.value == "hard") {
      SetHardRunChecked(e.target.checked);
    }
    if (e.target.value == "soft") {
      SetSoftRunChecked(e.target.checked);
    }
  };

  const RunReportFunction = async (jobtype='',runtype='') => {
    Setloading(false)
    try {
      const datavalue = await axios.get(
        `${process.env.REACT_APP_API_URL}domain/create_new_job_as_user/?user_domain_id=${esodata.domaindata.value.header_selected}&job_type=${jobtype}&soft_run=${runtype}`,
        {
          headers: {
            Authorization: `${ApiToken}`,
          },
        }
      );
      Setloading(false)
      if(datavalue.data)
      {
        if(datavalue.data.message)
        {

            toast.success(datavalue.data.message, {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
                });

        }
      }
    } catch (err) {
        Setloading(false)
        if(err.response)
        {

            if(err.response.data.field_error_list)
            {

                // toast.error(err.response.data.field_error_list[0].error_list, {
                //     position: "top-center",
                //     autoClose: 5000,
                //     hideProgressBar: false,
                //     closeOnClick: true,
                //     pauseOnHover: true,
                //     draggable: true,
                //     progress: undefined,
                //     theme: "light",
                //     });
                    if(err?.response?.status==599 || err?.response?.status==400 || err?.response?.status==500){
                      setapiOpen(true);
                      setapierrorMsg(err?.response?.data?.error)
                    }
                    else if(err?.code =="ERR_NETWORK" ||  err?.response?.status==404){
                      navigate('/errorpages')    
                    }

            }
            else if (err.response.data.error)
            {
                // toast.error(err.response.data.error, {
                //     position: "top-center",
                //     autoClose: 5000,
                //     hideProgressBar: false,
                //     closeOnClick: true,
                //     pauseOnHover: true,
                //     draggable: true,
                //     progress: undefined,
                //     theme: "light",
                //     });
                if(err?.response?.status==599 || err?.response?.status==400 || err?.response?.status==500){
                  setapiOpen(true);
                  setapierrorMsg(err?.response?.data?.error)
                }
                else if(err?.code =="ERR_NETWORK" ||  err?.response?.status==404){
                  navigate('/errorpages')    
                }

            }

         

        }
        else{

            // toast.error('Report generation has been failed', {
            // position: "top-center",
            // autoClose: 5000,
            // hideProgressBar: false,
            // closeOnClick: true,
            // pauseOnHover: true,
            // draggable: true,
            // progress: undefined,
            // theme: "light",
            // });
            if(err?.response?.status==599 || err?.response?.status==400 || err?.response?.status==500){
              setapiOpen(true);
              setapierrorMsg(err?.response?.data?.error)
            }
            else if(err?.code =="ERR_NETWORK" ||  err?.response?.status==404){
              navigate('/errorpages')    
            }

        }


  

      console.log(err);
    }
  };

  const [seconds, setSeconds] = useState(60);
  const [isActive, setIsActive] = useState(false);

  const apihandleClose = () => {
    setapiOpen(false);
  };
  useEffect(() => {
    let interval = null;

    if (isActive && seconds > 0) {
      interval = setInterval(() => {
        setSeconds(seconds => seconds - 1);
      }, 1000);
    } else if (isActive && seconds === 0) {
      CloseModalTrigger()
      RunReportTrigger()
      clearInterval(interval);
    }

    return () => clearInterval(interval);
  }, [isActive, seconds]);

  const handleStart = () => {
    setIsActive(true);
  };

  const handleReset = () => {
    setIsActive(false);
    setSeconds(60);
  };
  useEffect(()=>{

  if(props.RunReportPopShow===true)
  {
    handleStart()
  
  }
  if(props.RunReportPopShow===false)
  {
    handleReset()
  }
  },[props.RunReportPopShow])


  return (
    <>
    <ToastContainer
position="top-center"
autoClose={5000}
hideProgressBar={false}
newestOnTop={false}
closeOnClick
rtl={false}
pauseOnFocusLoss
draggable
pauseOnHover
theme="light"
/>


{
    loading===true ? <Loader/> :null
}
      <Dialog
        open={props.RunReportPopShow}
        onClose={CloseModalTrigger}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="competitor_test_filter job_date_filter run_report_filter"
      >
        <DialogTitle>{"Choose The Method"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            <div className="cnt_hdr_blw_wrap row">
     
              <div className="run_report_now_modal">
                <div class="main-container">
                  <div class="radio-buttons">
                    <label class="custom-radio">
                      <input
                        type="radio"
                        name="radio"
                        value={"hard"}
                        checked={HardRunChecked}
                        onChange={(e) => {
                          SetRunType(e);
                        }}
                      />
                      <span class="radio-btn">
                        <i class="las la-check"></i>
                        <div class="hobbies-icon">
                          <img src={ResetPng} />
                          <h3 class="">Hard Run</h3>
                        </div>
                      </span>
                    </label>
                    <label class="custom-radio">
                      <input
                        type="radio"
                        name="radio"
                        value={"soft"}
                        checked={SoftRunChecked}
                        onChange={(e) => {
                          SetRunType(e);
                        }}
                      />
                      <span class="radio-btn">
                        <i class="las la-check"></i>
                        <div class="hobbies-icon">
                          <img src={RestorePng} />
                          <h3 class="">Soft Run</h3>
                        </div>
                      </span>
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </DialogContentText>
        </DialogContent>
        <div className="run_report_info"><b>Soft Run:</b> Generates a quick report for known web pages.
        <br/>
<b>Hard Run: </b>Checks for new web pages and runs a complete report.</div>
  
        <DialogActions>
          <button
            className="run_rpt_btn rn_rpt_btn"
            onClick={RunReportTrigger}
            style={{ cursor: "pointer" }}
          >
            Submit
          </button>
          <button style={{ cursor: "pointer" }} onClick={CloseModalTrigger}>
            close
          </button>
        </DialogActions>
        <div className="run_report_info">{'The process for running the report will commence automatically after 60 seconds.. Time remaining: '+seconds}</div>
     
      </Dialog>

      <Dialog
          open={apiopen}
          onClose={apihandleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle>{"Alert!! "}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              {apierrorMsg}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={apihandleClose}>Close</Button>
          </DialogActions>
        </Dialog>
    </>
  );
};
export default RunReportNow;
