import { createSlice,createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
const APITOKEN=localStorage.getItem('eso_auth_token');

const CompetitorJobIdslice=createSlice({
    name:'competitior_job_id',
    initialState:{value:[]},
    reducers:{
      
    },
    extraReducers: (builder)=>{
        builder.addCase(GetCompetitorJobIds.fulfilled,(state,action)=>{
              
         
           
            state.value=action.payload
   
        })
      }
    
})

export const GetCompetitorJobIds=createAsyncThunk('eso/list-of-competitior_job_id',async (Domainid='')=>{
    const result=await axios.get(
      ''+process.env.REACT_APP_API_URL+'domain/get_report_job_details/?job_type=competitor_analysis&user_domain_id='+Domainid+'',{
        headers: {
          Authorization: `${APITOKEN}`,
        },
      }
    )

    var JobArray=[];

    if(result.data.jobs)
    {
        result.data.jobs.forEach(element => {

     

            JobArray.push({ label: element.completed_at, value: element.id });

       
            
        });
    }

    return JobArray

 

  
  })

export default CompetitorJobIdslice.reducer