import React, { useState, useEffect } from "react";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import axios from "axios";
import { useSelector } from "react-redux";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Loader from "../CommonLoader";
import EditKeywordAddForm from "./EditCampaignForm";
import GooglePng from "../../../Icons/google.png";
import BingPng from "../../../Icons/bing.png";
import ControlPointIcon from "@mui/icons-material/ControlPoint";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { useNavigate } from 'react-router-dom';
import { Tooltip } from "@mui/material";
import Button from "@mui/material/Button";


const CampaignSettingsList = (props) => {
  const [cardData, setCardData] = useState([]);
  const [loading, setLoading] = useState(false);
  const esodata = useSelector((state) => state);
  const ApiToken = esodata.apiauthkey.value;
  const navigate = useNavigate();
  const [deleteId, SetDeleteId] = useState("")
  const [autoheightval, SetAutoheightval] = useState(true)

  const StyledGridOverlay = styled("div")(({ theme }) => ({
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
    "& .ant-empty-img-1": {
      fill: theme.palette.mode === "light" ? "#aeb8c2" : "#262626",
    },
    "& .ant-empty-img-2": {
      fill: theme.palette.mode === "light" ? "#f5f5f7" : "#595959",
    },
    "& .ant-empty-img-3": {
      fill: theme.palette.mode === "light" ? "#dce0e6" : "#434343",
    },
    "& .ant-empty-img-4": {
      fill: theme.palette.mode === "light" ? "#fff" : "#1c1c1c",
    },
    "& .ant-empty-img-5": {
      fillOpacity: theme.palette.mode === "light" ? "0.8" : "0.08",
      fill: theme.palette.mode === "light" ? "#f5f5f5" : "#fff",
    },
  }));

  const MyCustomNoRowsOverlay = () => (
    <StyledGridOverlay>
      <svg
        width="120"
        height="100"
        viewBox="0 0 184 152"
        aria-hidden
        focusable="false"
      >
        <g fill="none" fillRule="evenodd">
          <g transform="translate(24 31.67)">
            <ellipse
              className="ant-empty-img-5"
              cx="67.797"
              cy="106.89"
              rx="67.797"
              ry="12.668"
            />
            <path
              className="ant-empty-img-1"
              d="M122.034 69.674L98.109 40.229c-1.148-1.386-2.826-2.225-4.593-2.225h-51.44c-1.766 0-3.444.839-4.592 2.225L13.56 69.674v15.383h108.475V69.674z"
            />
            <path
              className="ant-empty-img-2"
              d="M33.83 0h67.933a4 4 0 0 1 4 4v93.344a4 4 0 0 1-4 4H33.83a4 4 0 0 1-4-4V4a4 4 0 0 1 4-4z"
            />
            <path
              className="ant-empty-img-3"
              d="M42.678 9.953h50.237a2 2 0 0 1 2 2V36.91a2 2 0 0 1-2 2H42.678a2 2 0 0 1-2-2V11.953a2 2 0 0 1 2-2zM42.94 49.767h49.713a2.262 2.262 0 1 1 0 4.524H42.94a2.262 2.262 0 0 1 0-4.524zM42.94 61.53h49.713a2.262 2.262 0 1 1 0 4.525H42.94a2.262 2.262 0 0 1 0-4.525zM121.813 105.032c-.775 3.071-3.497 5.36-6.735 5.36H20.515c-3.238 0-5.96-2.29-6.734-5.36a7.309 7.309 0 0 1-.222-1.79V69.675h26.318c2.907 0 5.25 2.448 5.25 5.42v.04c0 2.971 2.37 5.37 5.277 5.37h34.785c2.907 0 5.277-2.421 5.277-5.393V75.1c0-2.972 2.343-5.426 5.25-5.426h26.318v33.569c0 .617-.077 1.216-.221 1.789z"
            />
          </g>
          <path
            className="ant-empty-img-3"
            d="M149.121 33.292l-6.83 2.65a1 1 0 0 1-1.317-1.23l1.937-6.207c-2.589-2.944-4.109-6.534-4.109-10.408C138.802 8.102 148.92 0 161.402 0 173.881 0 184 8.102 184 18.097c0 9.995-10.118 18.097-22.599 18.097-4.528 0-8.744-1.066-12.28-2.902z"
          />
          <g className="ant-empty-img-4" transform="translate(149.65 15.383)">
            <ellipse cx="20.654" cy="3.167" rx="2.849" ry="2.815" />
            <path d="M5.698 5.63H0L2.898.704zM9.259.704h4.985V5.63H9.259z" />
          </g>
        </g>
      </svg>
      <Box sx={{ mt: 1 }}>No Data</Box>
    </StyledGridOverlay>
  );

  const rows = cardData ?? [];

  const RenderCustomcells = (SearchEngines) => {
    return SearchEngines.map((item) => (
      <ul className="google_bing_campagin_ul">
        <li className="engines">
          {item.name === "Google" ? (
            <img style={{ width: "30px", height: "30px" }} src={GooglePng} />
          ) : null}
          {item.name === "Bing" ? (
            <img style={{ width: "30px", height: "30px" }} src={BingPng} />
          ) : null}

          <span style={{ width: "36%" }}>{item.name}</span>

          <div className="child_ul_show" onClick={ShowNearbyUl}>
            {" "}
            <span className="show">
              <ControlPointIcon />{" "}
            </span>
            <span className="hide">
              <HighlightOffIcon />{" "}
            </span>
          </div>
        </li>
        <ul className="child_ul hidden">
          {item.countries.map((item) => (
            <li>{item}</li>
          ))}
        </ul>
      </ul>
    ));
  };
  function addcampaign() {
    openModal()
  }
  const handleDeleteClick = (id) => {
    SetDeleteId(id);
    setCampIsOpen(true);
  };

  const ShowNearbyUl = (e) => {
    const Element = e.target.closest(".engines");
    const nextElement = Element.nextElementSibling;
    if (nextElement) {
      // Toggle the 'hidden' class to show/hide the element
      nextElement.classList.toggle("hidden");
    }

    //show hide icon

    const lastChild = Element.lastElementChild;

    // Find the span with the class "initialClass" inside the parent div
    const spanToChange = lastChild.querySelector("span.show");
    const spanToChangeHide = lastChild.querySelector("span.hide");

    // Change the class of the found span
    if (spanToChange) {
      spanToChange.classList.remove("show");
      spanToChange.classList.add("hide");
    }

    if (spanToChangeHide) {
      spanToChangeHide.classList.remove("hide");
      spanToChangeHide.classList.add("show");
    }

    //show hide icon end
  };

  const DeleteCampaignSettings = async (e) => {
    // const Id = e.target
    //   .closest(".DeleteBrandedtermClass")
    //   .getAttribute("data-id");
    setCampIsOpen(false)
    const Id = deleteId;

    try {
      const datavalue = await axios.get(
        `${process.env.REACT_APP_API_URL}keywords/campaign_delete/?user_domain_id=${esodata.domaindata.value.header_selected}&campaign_id=${Id}`,
        {
          headers: {
            Authorization: `${ApiToken}`,
          },
        }
      ).catch((error) => {
        if (error?.response?.status == 599 || error?.response?.status == 400 || error?.response?.status == 500) {
          setapiOpen(true);
          setapierrorMsg(error?.response?.data?.error)
        }
        else if (error?.code == "ERR_NETWORK" || error?.response?.status == 404) {
          navigate('/errorpages')
        }
        // console.log("kannan",error)
      });
    } catch (err) {
      console.log(err);
    }

    callUserdataApi();
  };

  const columns = [
    {
      field: "sid",
      headerName: "#",
      minWidth: 100,
      flex: 1,
      disableClickEventBubbling: true,
      headerAlign: "center",
      align: "center",
      description: 'Index of the row',
    },

    {
      field: "campaign_name",
      headerName: "Campaign Name",
      minWidth: 400,
      flex: 1,
      headerAlign: "center",
      align: "center",
      sortable: true,
      disableClickEventBubbling: true,
      description: 'Campaign names that have been added to the domain.',
    },
    {
      field: "total_keywords_tracked",
      headerName: "Total Keywords",
      minWidth: 300,
      flex: 1,
      headerAlign: "center",
      align: "center",
      sortable: true,
      description: 'Total number of keywords tracked in the campaign',
      disableClickEventBubbling: true,
      // renderCell: (cardData) => {
      //   var SearchEngines = [];

      //   if (cardData.row.search_engines) {
      //     SearchEngines = cardData.row.search_engines;
      //   }

      //   return RenderCustomcells(SearchEngines);
      // },
    },
    {
      field: "edit",
      headerName: "Edit",
      minWidth: 200,
      flex: 1,
      headerAlign: "center",
      align: "center",
      sortable: false,
      disableClickEventBubbling: true,
      renderCell: (cardData) => {
        return (
          <Tooltip title={'Click here to edit the campaign settings'}>
            <a
              className="EditBrandedTermClass"
              href="javascript:void(0)"
              data-id={cardData.row.campaign_id}
            >
              <button
                className="buttonView edit_icon"
                onClick={EditCampaignSettings}
              >


                <EditIcon />
              </button>
            </a>
          </Tooltip>
        );
      },
    },
    {
      field: "delete",
      headerName: "Delete",
      minWidth: 200,
      flex: 1,
      headerAlign: "center",
      align: "center",
      sortable: false,
      disableClickEventBubbling: true,
      renderCell: (cardData) => {
        return (
          <Tooltip title={'Click here to delete the keyword'}>
            <a className="DeleteBrandedtermClass" href="javascript:void(0)" >
              <button className="buttonView trash_icon"
                //  onClick={handleClickOpen} 
                onClick={() => handleDeleteClick(cardData.row.campaign_id)}


                id={cardData.row.campaign_id}>

                <DeleteIcon />
              </button>
            </a>
          </Tooltip>
        );
      },
    },
  ];

  const [EditCampaignData, SetEditCampaignData] = useState();
  const [EditCampaignDataId, SetEditCampaignDataId] = useState('')
  const [EditCamId, SetEditCamId] = useState('')

  const EditCampaignSettings = async (e) => {


    SetEditCampaignData();

    const Id = parseInt(
      e.target.closest(".EditBrandedTermClass").getAttribute("data-id")
    );

    const desiredObject = cardData.find(item => item.campaign_id == Id);
    SetEditBrandTerm(desiredObject.campaign_name)

    SetEditCamId(Id)
    openModal(Id)


    // navigate(`/edit-campaign/${Id}`)

    //  SetEditCampaignDataId(Id)


    // const datavalue = await axios.get(
    //   `${process.env.REACT_APP_API_URL}keywords/campaign_data/?campaign_id=${Id}&user_domain_id=${esodata.domaindata.value.header_selected}&domain_section_id=${esodata.sectiondomaindata.value}`,
    //   {
    //     headers: {
    //       Authorization: `${ApiToken}`,
    //     },
    //   }
    // );

    // if(datavalue.data)
    // {
    //   SetEditCampaignData(datavalue.data)
    // }

    // props.SetEnableKeywordAddForm(false)

    // window.scrollTo(0, 0);
  };

  const callUserdataApi = async (jobid = "") => {
    setLoading(true);
    setCardData([]);

    try {
      const datavalue = await axios.get(
        `${process.env.REACT_APP_API_URL}keywords/v2/campaign_table/?user_domain_id=${esodata.domaindata.value.header_selected}`,
        {
          headers: {
            Authorization: `${ApiToken}`,
          },
        }
      ).catch((error) => {
        if (error?.response?.status == 599 || error?.response?.status == 400 || error?.response?.status == 500) {
          setapiOpen(true);
          setapierrorMsg(error?.response?.data?.error)
        }
        else if (error?.code == "ERR_NETWORK" || error?.response?.status == 404) {
          navigate('/errorpages')
        }
        // console.log("kannan",error)
      });



      if (datavalue?.data?.result && datavalue?.data?.result.length > 0) {
        if (datavalue?.data?.result) {
          const newData = datavalue?.data?.result.map((obj, index) => {
            return { ...obj, sid: index + 1 };
          });
          SetAutoheightval(false)
          setCardData(newData);
        }
      }
      else {
        SetAutoheightval(true)
        setCardData("");
      }
    } catch (err) {
      console.log(err);
    }

    setLoading(false);
  };

  useEffect(() => {
    callUserdataApi();
  }, []);

  useEffect(() => {

    if (props.EnableKeywordAddForm === true) {
      SetEditCampaignData()
    }

    if (props.CallCampaignTable === true) {
      callUserdataApi()
      props.SetCallCampaignTable(false)
    }

  }, [props])

  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [campIsOpen, setCampIsOpen] = useState(false);
  const [apiopen, setapiOpen] = React.useState(false);
  const [apierrorMsg, setapierrorMsg] = useState();
  const [ErrorMsg, SetErrorMsg] = useState('')
  const [EditBrandTerm, SetEditBrandTerm] = useState('')
  function closeModal() {
    setIsOpen(false);
    setCampIsOpen(false);
    SetEditCamId('')
    SetErrorMsg('')
  }
  function delopenModal(e) {
    setCampIsOpen(true)
    SetDeleteId(e.target.id)
  }
  function openModal(passid = '') {
    setIsOpen(true);

    if (passid === '') {
      SetEditBrandTerm('')
      SetCamPopTitle('Add Campaign Data')
    }
    else {
      SetCamPopTitle('Edit Campaign Data')
    }
  }
  const SubmitEditBrand = async () => {


    if (EditBrandTerm === null || EditBrandTerm === '') {

      SetErrorMsg('Please enter the campaign name')

    }
    else {
      SetErrorMsg('')

      try {

        var response;

        if (EditCamId === '') {

          response = await axios.get(
            `${process.env.REACT_APP_API_URL}keywords/add_new_campaign/?user_domain_id=${esodata.domaindata.value.header_selected}&campaign_name=${EditBrandTerm}`,
            {
              headers: {
                Authorization: `${ApiToken}`,
              },
            }
          ).catch((error) => {
            if (error?.response?.status == 599 || error?.response?.status == 400 || error?.response?.status == 500) {
              setapiOpen(true);
              setapierrorMsg(error?.response?.data?.error)
            }
            else if (error?.code == "ERR_NETWORK" || error?.response?.status == 404) {
              navigate('/errorpages')
            }

          });
          if (response.status == 200) {
            setapiOpen(true);
            setapierrorMsg("Campaign Added Successfully")
          }
        }
        else {

          response = await axios.get(
            `${process.env.REACT_APP_API_URL}keywords/edit_campaign/?user_domain_id=${esodata.domaindata.value.header_selected}&new_campaign_name=${EditBrandTerm}&campaign_id=${EditCamId}`,
            {
              headers: {
                Authorization: `${ApiToken}`,
              },
            }
          ).catch((error) => {
            if (error?.response?.status == 599 || error?.response?.status == 400 || error?.response?.status == 500) {
              setapiOpen(true);
              setapierrorMsg(error?.response?.data?.error)
            }
            else if (error?.code == "ERR_NETWORK" || error?.response?.status == 404) {
              navigate('/errorpages')
            }
            // console.log("kannan",error)
          });

        }



        if (response?.data) {
          callUserdataApi()
          closeModal()
        }

      } catch (err) {
        if (err?.response?.data?.error) {
          SetErrorMsg(err.response.data.error)
        }
        console.log(err)
      }

      props.GetCampaignData()

    }





  }
  const apihandleClose = () => {
    setapiOpen(false);
  };

  const [CamPopTitle, SetCamPopTitle] = useState('')
  return (
    <div>
      <div className="cnt_hdr_blw_wrap add_keyword_button_wrap">
        <button className="run_rpt_btn rn_rpt_btn" onClick={e => addcampaign(e)}>
          Add Campaign
        </button>
      </div>
      {(EditCampaignData && props.EnableKeywordAddForm === false) ? (
        <EditKeywordAddForm SetEditCampaignData={SetEditCampaignData} EditCampaignData={EditCampaignData} SetEnableKeywordAddForm={props.SetEnableKeywordAddForm} EditCampaignDataId={EditCampaignDataId} callUserdataApi={callUserdataApi} />
      ) : null}

      {
        (true) ? <div className="data_table brand_list_table campapgin_settings_list_table" style={{ width: "100%" }}>
          <div style={{ height: 550, width: "100%" }}>
            <DataGrid

              getRowId={(row) => row.sid}
              slots={{
                noRowsOverlay: MyCustomNoRowsOverlay,
                toolbar: GridToolbar
              }}
              slotProps={{
                toolbar: {

                  showQuickFilter: true,

                },
              }}
              rows={rows}
              columns={columns}
              sx={{
                "&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell": {
                  py: "8px",
                },
                "&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell": {
                  py: "15px",
                },
                "&.MuiDataGrid-root--densityComfortable .MuiDataGrid-cell": {
                  py: "22px",
                },
                "& .MuiDataGrid-columnHeaderTitle": {
                  whiteSpace: "normal",
                  lineHeight: "normal",
                },
                "&, [class^=MuiDataGrid]": { border: "none" },
                ".MuiDataGrid-virtualScroller": {
                  height: "auto !important",
                  overflowY: "auto",
                },
                "& .MuiDataGrid-cell:first-child": {
                  position: "unset",
                  top: "0",
                  left: "0",
                  paddingLeft: "1.5rem",
                  zIndex: 999,
                },
                "& .MuiDataGrid-columnHeader:first-child": {
                  position: "sticky",
                  top: "0",
                  left: "0",
                  paddingLeft: "1.5rem",
                  border: "none",
                  zIndex: 999,
                },
                "& .MuiDataGrid-columnSeparator--sideRight": {
                  display: "none"
                },
              }}
              getRowHeight={() => "auto"}
              autoHeight={autoheightval ? true : false}
              initialState={{
                pagination: {
                  paginationModel: {
                    pageSize: 10,
                  },
                },
              }}
              // loading={loading}
              pageSizeOptions={[10, 30, 50, 75, 100]}
              disableColumnFilter
              disableColumnSelector
              disableDensitySelector
            />
          </div>
        </div> : null
      }

      <Dialog
        open={modalIsOpen}
        onClose={closeModal}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="competitor_test_filter job_date_filter keyword_edit_form"
      >
        <DialogTitle>{CamPopTitle}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            <div className="cnt_hdr_blw_wrap row">

              <div>

                <div className="">
                  <div className="keyword_edit_form_row">
                    <span>Campaign Name</span>
                    <input type="text" defaultValue={EditBrandTerm} onChange={(e) => { SetEditBrandTerm(e.target.value) }} />
                  </div>


                </div>


                <div className="error_plagarism_report">
                  {
                    ErrorMsg
                  }

                </div>
              </div>

            </div>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <button className="run_rpt_btn rn_rpt_btn" onClick={SubmitEditBrand} style={{ cursor: 'pointer' }} >
            Submit
          </button>
          <button style={{ cursor: 'pointer' }} onClick={closeModal}>close</button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={campIsOpen}
        onClose={closeModal}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="competitor_test_filter job_date_filter"
      >
        <DialogTitle>Alert</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            <div className="cnt_hdr_blw_wrap row">

              Are you sure you want to delete this Campaign Name?

            </div>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <button className="run_rpt_btn rn_rpt_btn" onClick={DeleteCampaignSettings} style={{ cursor: 'pointer' }} >
            OK
          </button>
          <button style={{ cursor: 'pointer' }} onClick={closeModal}>close</button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={apiopen}
        onClose={apihandleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle>{"Alert!! "}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            {apierrorMsg}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={apihandleClose}>Close</Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={apiopen}
        onClose={apihandleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle>{"Alert!! "}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            {apierrorMsg}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={apihandleClose}>Close</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default CampaignSettingsList;
