import React, { useState, useEffect, useMemo } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const PdfFile = React.memo((props) => {
  const [childcardData, setChildcardData] = useState([]);

  const navigate = useNavigate();
  const esodata = useSelector((state) => state);
  const ApiToken = esodata.apiauthkey.value;
  const { jobid } = useParams();

  useEffect(() => {
    setChildcardData(props.cardData);
  }, [props.cardData]);

  const successRows = useMemo(() => (
    childcardData.detailed_data?.success?.map((row, index) => (
      <div key={index} style={{ marginLeft: "30px" }}>
        <span><b><h3>{row.title}</h3></b></span>
        <p><strong>Requirement:</strong> {row.requirement}</p>
        <p><strong>Response:</strong> {row.response}</p>
      </div>
    ))
  ), [childcardData.detailed_data?.success]);

  const failureRows = useMemo(() => (
    childcardData.detailed_data?.failure?.map((row, index) => (
      <div key={index} style={{ marginLeft: "30px" }}>
        <span><b><h3>{row.title}</h3></b></span>
        <p><strong>Requirement:</strong> {row.requirement}</p>
        <p><strong>Response:</strong> {row.response}</p>
      </div>
    ))
  ), [childcardData.detailed_data?.failure]);

  return (
    <div className="cnt_hdr_file_access">
      <div className="cnt_hdr_content_box_outer fileaccesstopinner">
        <div className="plagiarisam_missing_waring_wrap filename">
          <p>{childcardData.file_name}</p>
          <p><b>File Name</b></p>
        </div>
        <div className="plagiarisam_missing_waring_wrap">
          <p>{childcardData.score}</p>
          <p><b>Score</b></p>
        </div>
      </div>
      <div className="cnt_hdr_content_table fileaccessreport_inner">
        <div className="box_wrap">
          <div className="hor_line">
            <center><h2>Success</h2></center>
          </div>
          {successRows}
        </div>
      </div>
      <div className="cnt_hdr_content_table fileaccessreport_inner">
        <div className="box_wrap">
          <div className="hor_line">
            <center><h2>Failure</h2></center>
          </div>
          {failureRows}
        </div>
      </div>
    </div>
  );
});

export default PdfFile;