import { createSlice } from "@reduxjs/toolkit";

const PageredirectSlice=createSlice({
    name:'redirectvalue',
    initialState:{value:{eso_user_token:localStorage.getItem('eso_redirect_value')}},
    reducers:{
        SetredirectValue:(state,action)=>{
           localStorage.setItem("eso_redirect_value", action.payload.eso_redirect_value);
           state.value=action.payload
        }
    }
})


export const {SetredirectValue}=PageredirectSlice.actions


export default PageredirectSlice.reducer