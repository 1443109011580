import React, { useState, useEffect, useContext } from "react";
import { styled, useTheme } from "@mui/material/styles";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import Box from "@mui/material/Box";
import axios from "axios";
import "./../style.css";

import { Link, useParams } from "react-router-dom";

import calendarpng from "./../Asset/New_Assert/ESO_Icon/Filter.svg";
import pdficon from "./../Asset/PDF.png";
import csvicon from "./../Asset/CSV.png";
import moment from "moment";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import fav_icon from "./../Asset/New_Assert/ESO_ICON.png";
import Select from "react-dropdown-select";
import { color } from "d3-color";
import { interpolateRgb } from "d3-interpolate";
import { useSelector } from "react-redux";
import { Tooltip } from "@mui/material";
import LinkIcon from '@mui/icons-material/Link';
import RunReportNow from "../RunReportNow";
import { Helmet } from 'react-helmet';
import { ToastContainer, toast } from 'react-toastify';
import QuestionMarkIcon from "@mui/icons-material/QuestionMark";
import Rightsidebar from "../Rightsidebar/Rightsidebar";
import { useNavigate } from "react-router-dom";
import errorimages from "../Asset/download1.png"
import { Commonfun } from './../Commonfun';
import { timezonechange } from './../Commonfun';
import { timezoneval } from './../Commonfun';
import Loader from "../CommonLoader";
import { AppContext } from "../../../Context/Context";
export default function AccessibilityList() {
  const { isMenuExpanded, setIsMenuExpanded } = useContext(AppContext);
  const [cardData, setCardData] = useState([]);
  const [jobidData, setjobidData] = useState("");
  const [jobidValue, setjobValue] = useState("");
  const [loading, setLoading] = useState(false);

  const [modalIsOpen, setIsOpen] = React.useState(false);

  const [open, setOpen] = React.useState(false);
  const [jobDate, setjobDate] = useState("");
  const [completedate, setcompletedate] = useState("");
  const [selectval, setSelectval] = useState("");
  const [SectionOptions, SetSectionOptions] = useState([]);
  const [DoaminSectionUrl, SetDoaminSectionUrl] = useState([])
  const [Domainsectiondata, SetDomainsectiondata] = useState([])
  const esodata = useSelector((state) => state);
  const [autoheightval, SetAutoheightval] = useState(true)
  const [initialfaqDateval, SetInitialfaqDateval] = useState([]);
  const [faqData, setfaqData] = useState("2")
  const [toggle, setToggle] = useState(false);
  const [apiopen, setapiOpen] = React.useState(false);
  const [apierrorMsg, setapierrorMsg] = useState();

  const navigate = useNavigate();

  const ApiToken = esodata.apiauthkey.value
  let result = ApiToken.split(" ");

  let detailurl = `${process.env.REACT_APP_DOMAIN}accessibility-report-detail/`;

  const isTabletView = window.innerWidth <= 768;


  const apihandleClose = () => {
    setapiOpen(false);
  };

  const StyledGridOverlay = styled("div")(({ theme }) => ({
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
    "& .ant-empty-img-1": {
      fill: theme.palette.mode === "light" ? "#aeb8c2" : "#262626",
    },
    "& .ant-empty-img-2": {
      fill: theme.palette.mode === "light" ? "#f5f5f7" : "#595959",
    },
    "& .ant-empty-img-3": {
      fill: theme.palette.mode === "light" ? "#dce0e6" : "#434343",
    },
    "& .ant-empty-img-4": {
      fill: theme.palette.mode === "light" ? "#fff" : "#1c1c1c",
    },
    "& .ant-empty-img-5": {
      fillOpacity: theme.palette.mode === "light" ? "0.8" : "0.08",
      fill: theme.palette.mode === "light" ? "#f5f5f5" : "#fff",
    },
  }));


  const MyCustomNoRowsOverlay = () => (
    <StyledGridOverlay>
      <svg
        width="120"
        height="100"
        viewBox="0 0 184 152"
        aria-hidden
        focusable="false"
      >
        <g fill="none" fillRule="evenodd">
          <g transform="translate(24 31.67)">
            <ellipse
              className="ant-empty-img-5"
              cx="67.797"
              cy="106.89"
              rx="67.797"
              ry="12.668"
            />
            <path
              className="ant-empty-img-1"
              d="M122.034 69.674L98.109 40.229c-1.148-1.386-2.826-2.225-4.593-2.225h-51.44c-1.766 0-3.444.839-4.592 2.225L13.56 69.674v15.383h108.475V69.674z"
            />
            <path
              className="ant-empty-img-2"
              d="M33.83 0h67.933a4 4 0 0 1 4 4v93.344a4 4 0 0 1-4 4H33.83a4 4 0 0 1-4-4V4a4 4 0 0 1 4-4z"
            />
            <path
              className="ant-empty-img-3"
              d="M42.678 9.953h50.237a2 2 0 0 1 2 2V36.91a2 2 0 0 1-2 2H42.678a2 2 0 0 1-2-2V11.953a2 2 0 0 1 2-2zM42.94 49.767h49.713a2.262 2.262 0 1 1 0 4.524H42.94a2.262 2.262 0 0 1 0-4.524zM42.94 61.53h49.713a2.262 2.262 0 1 1 0 4.525H42.94a2.262 2.262 0 0 1 0-4.525zM121.813 105.032c-.775 3.071-3.497 5.36-6.735 5.36H20.515c-3.238 0-5.96-2.29-6.734-5.36a7.309 7.309 0 0 1-.222-1.79V69.675h26.318c2.907 0 5.25 2.448 5.25 5.42v.04c0 2.971 2.37 5.37 5.277 5.37h34.785c2.907 0 5.277-2.421 5.277-5.393V75.1c0-2.972 2.343-5.426 5.25-5.426h26.318v33.569c0 .617-.077 1.216-.221 1.789z"
            />
          </g>
          <path
            className="ant-empty-img-3"
            d="M149.121 33.292l-6.83 2.65a1 1 0 0 1-1.317-1.23l1.937-6.207c-2.589-2.944-4.109-6.534-4.109-10.408C138.802 8.102 148.92 0 161.402 0 173.881 0 184 8.102 184 18.097c0 9.995-10.118 18.097-22.599 18.097-4.528 0-8.744-1.066-12.28-2.902z"
          />
          <g className="ant-empty-img-4" transform="translate(149.65 15.383)">
            <ellipse cx="20.654" cy="3.167" rx="2.849" ry="2.815" />
            <path d="M5.698 5.63H0L2.898.704zM9.259.704h4.985V5.63H9.259z" />
          </g>
        </g>
      </svg>
      <Box sx={{ mt: 1 }}>No Data</Box>
    </StyledGridOverlay>
  );



  const handleClose = () => {
    setOpen(false);
  };

  const [validation, setValidation] = useState({
    dateerror: "",
  });

  const faqdatafun = async (menubutval = "") => {
    var datavar = `${process.env.REACT_APP_API_URL}domain/frequently_asked_questions/?menu=54`

    try {
      const doaminsectiondatavalue = await axios.get(
        datavar,
        {
          headers: {
            Authorization: `${ApiToken}`,
          },
        }
      ).catch((error) => {
        if (error?.response?.status == 599 || error?.response?.status == 400 || error?.response?.status == 500) {
          setapiOpen(true);
          setapierrorMsg(error?.response?.data?.error)
        }
        else if (error?.code == "ERR_NETWORK" || error?.response?.status == 404) {
          navigate('/errorpages')
        }
        // console.log("kannan",error)
      });
      if (doaminsectiondatavalue?.data) {
        console.log("domain data", doaminsectiondatavalue?.data?.faqs)
        SetInitialfaqDateval(doaminsectiondatavalue?.data?.faqs)
      }
    } catch (err) { }
  }

  useEffect(() => {
    callUserdataApi();
    // LoadAddFormFilterOptions();
    GetFilterOptions();
    faqdatafun()
    const findVirtualScroller = () => {
      const virtualScrollerElement = document.querySelector(
        ".MuiDataGrid-virtualScroller"
      );
      if (!virtualScrollerElement) {
        setTimeout(findVirtualScroller, 100);
      } else {
        virtualScrollerElement.addEventListener(
          "scroll",
          handleScrollHorizontal
        );
        return () => {
          virtualScrollerElement.removeEventListener(
            "scroll",
            handleScrollHorizontal
          );
        };
      }
    };
    findVirtualScroller();
  }, []);

  let debounceTimeout;

  const handleScrollHorizontal = (() => {
    clearTimeout(debounceTimeout);
    debounceTimeout = setTimeout(() => {
      const isTabletView = window.innerWidth <= 768;
      console.log("jgyy", isTabletView ? "relative" : "sticky", window.innerWidth);

      const currentScrollPos = document.querySelector(
        ".MuiDataGrid-virtualScroller"
      ).scrollLeft;

      const columnsHeaders = document.querySelectorAll(
        isTabletView
          ? ".MuiDataGrid-columnHeader" // All columns scroll in tablet view
          : ".MuiDataGrid-columnHeader:not(.MuiDataGrid-columnHeader:nth-child(-n+3))" // Freeze first three columns on larger screens
      );

      columnsHeaders.forEach((columnHeader) => {
        columnHeader.style.transform = `translate3d(-${currentScrollPos}px, 0px, 0px)`;
      });
    }, 20);

  })
  const columns = [

    {
      field: "sid",
      headerName: "#",
      minWidth: 100,
      flex: 1,
      disableClickEventBubbling: true,
      headerAlign: "center",
      align: "center",

    },

    {
      field: "link__url",
      headerName: "URL",
      description: "URL of the web page",
      minWidth: 550,
      flex: 1,
      disableClickEventBubbling: true,
      headerAlign: 'center',
      align: 'center',
      sortable: true,
      renderCell: (cardData) => {
        return (
          <>
            <Tooltip title={cardData.row.link__url}>
              <div className="prefered_url_external">
                <LinkIcon />
                <a target="_blank" href={cardData.row.link__url}>
                  {cardData.row.link__url}
                </a>
              </div>
            </Tooltip>
          </>
        );
      },

    },

    {
      field: "forms",
      headerName: "Forms",
      description: "Form Count",
      minWidth: 150,
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      sortable: true,

      disableClickEventBubbling: true,
      renderCell: (cardData) => {
        return cardData.row.table_data.forms;
      },

    },
    {
      field: "menus",
      headerName: "Menus",
      description: "Menus Count",
      minWidth: 150,
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      disableClickEventBubbling: true,
      renderCell: (cardData) => {
        return cardData.row.table_data.menus;
      },

    },
    {
      field: "tables",
      headerName: "Tables",
      description: "Tables Count",
      minWidth: 150,
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      disableClickEventBubbling: true,
      renderCell: (cardData) => {
        return cardData.row.table_data.tables;
      },

    },
    {
      field: "titles",
      headerName: "Titles",
      description: "Title Count",
      minWidth: 150,
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      disableClickEventBubbling: true,
      renderCell: (cardData) => {
        return cardData.row.table_data.titles;
      },

    },
    {
      field: "general",
      headerName: "General",
      minWidth: 150,
      flex: 1,
      headerAlign: 'center',
      description: "General Count",
      align: 'center',
      disableClickEventBubbling: true,
      renderCell: (cardData) => {
        return cardData.row.table_data.general;
      },

    },
    {
      field: "document",
      headerName: "Document",
      minWidth: 150,
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      description: "Document Count",
      disableClickEventBubbling: true,
      renderCell: (cardData) => {
        return cardData.row.table_data.document;
      },

    },
    {
      field: "graphics",
      headerName: "Graphics",
      minWidth: 150,
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      disableClickEventBubbling: true,
      description: "Graphics Count",
      renderCell: (cardData) => {
        return cardData.row.table_data.graphics;
      },

    },
    {
      field: "carousels",
      headerName: "Carousels",
      minWidth: 150,
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      description: "Carousels Count",
      disableClickEventBubbling: true,
      renderCell: (cardData) => {
        return cardData.row.table_data.carousels;
      },

    },
    {
      field: "clickables",
      headerName: "Clickables",
      minWidth: 150,
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      disableClickEventBubbling: true,
      description: "Clickables Count",
      renderCell: (cardData) => {
        return cardData.row.table_data.clickables;
      },

    },
    {
      field: "orientation",
      headerName: "Orientation",
      minWidth: 150,
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      description: "Orientation Count",
      disableClickEventBubbling: true,
      renderCell: (cardData) => {
        return cardData.row.table_data.orientation;
      },

    },
    {
      field: "readability",
      headerName: "Readability",
      minWidth: 150,
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      disableClickEventBubbling: true,
      description: "Readability Count",
      renderCell: (cardData) => {
        return cardData.row.table_data.readability;
      },

    },

    {
      field: "",
      headerName: "View",
      minWidth: 150,
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      sortable: false,
      headerClassName: 'hide_option',
      renderCell: (cardData) => {


        return (
          <a href={detailurl + `${cardData.row.id}` + "/" + jobidValue}>
            <button className="buttonView">
              View Report
              <img
                src={fav_icon}
                className="view_std_img"
                alt="view report icon"
              />
            </button>
          </a>
        );
      },
    },

  ];

  const rows = cardData ?? [];
  const callUserdataApi = async (jobid = "", SelectedWcagType = "", domainsecdata = "") => {
    setLoading(true);
    if (domainsecdata == "")
      domainsecdata = Domainsectiondata;

    setCardData([])
    try {
      const datavalue = await axios.get(
        `${process.env.REACT_APP_API_URL}technical/accessibility2_table/?user_domain_id=${esodata.domaindata.value.header_selected}&job_id=${selectval}`, {
        headers: {
          Authorization: `${ApiToken}`,
        },
      }
      ).catch((error) => {
        if (error?.response?.status == 599 || error?.response?.status == 400 || error?.response?.status == 500) {
          setapiOpen(true);
          setapierrorMsg(error?.response?.data?.error)
        }
        else if (error?.code == "ERR_NETWORK" || error?.response?.status == 404) {
          navigate('/errorpages')
        }
        // console.log("kannan",error)
      });
      const dataval = datavalue?.data?.table_data;
      if(datavalue?.data?.job_date)
        setjobDate(moment(datavalue?.data?.job_date).format("MMMM DD,YYYY HH:mm"))    
        else
        setjobDate(moment(datavalue?.data?.job_date).format("MMMM DD,YYYY")) 

      if (dataval && dataval.length > 0) {
        SetAutoheightval(false)

        const newData = dataval.map((obj, index) => {

          return { ...obj, sid: index + 1 };
        });

        console.log("kannan", newData)
        setCardData(newData);

        // setjobDate(moment(datavalue?.data?.job_date).format("MMMM DD,YYYY HH:mm"));
        setjobValue(datavalue?.data?.job_id)
      }
      else {
        setCardData("")
        SetAutoheightval(true)
      }
    }
    catch (err) {
      console.log('err', err)
    }


    setLoading(false);
  };

  const reportNow = async () => {

    var errs = 1;
    let errors = {};

    if (selectval == "" || SelectedWcagType == '') {
      errors.dateerror = "All Fields are required.";
      errs = 2;
    } else {
      errors.dateerror = "";
      errs = 1;
      setValidation(errors);
    }
    if (errs == 2) {
      return setValidation(errors);
    }

    closeModal();
    setLoading(true);


    var domainsec = "";
    callUserdataApi("", SelectedWcagType, domainsec = "")

  };
  function openModal() {
    setfaqData(2)
    setIsOpen(true);
  }
  function closeModal() {
    setIsOpen(false);



  }

  const [WcagType, SetWcagType] = useState([])
  const [SelectedWcagType, SetSelectedWcagType] = useState('AA')
  const [DefaultSelectedWcagType, SetDefaultSelectedWcagType] = useState([])
  const GetFilterOptions = async () => {
    setcompletedate([])
    try {
      const FetchList = await axios.get(
        `${process.env.REACT_APP_API_URL}domain/get_report_job_details/?user_domain_id=${esodata.domaindata.value.header_selected}&job_type=accessibility2`, {
        headers: {
          Authorization: `${ApiToken}`,
        },
      }
      ).catch((error) => {
        if (error?.response?.status == 599 || error?.response?.status == 400 || error?.response?.status == 500) {
          setapiOpen(true);
          setapierrorMsg(error?.response?.data?.error)
        }
        else if (error?.code == "ERR_NETWORK" || error?.response?.status == 404) {
          navigate('/errorpages')
        }
        // console.log("kannan",error)
      });
      if (FetchList.data) {
        if (FetchList?.data?.jobs.length > 0) {
          setSelectval(FetchList.data.jobs[0].id);
          SetdEngineoption(FetchList.data.jobs[0]);
          setcompletedate(FetchList.data.jobs);
        }
      }

      var WcagTypeArray = [];
      WcagTypeArray.push({ label: 'A', value: 'A' })
      WcagTypeArray.push({ label: 'AA', value: 'AA' })
      WcagTypeArray.push({ label: 'AAA', value: 'AAA' })

      SetWcagType(WcagTypeArray)
      SetDefaultSelectedWcagType(WcagTypeArray[1])

    } catch (err) {
      console.log('err', err)
    }

  }

  const OnsetSelectval = (values) => {
    setSelectval(values[0].id)

  }
  const OnSetSelectedWcagType = (values) => {
    SetDefaultSelectedWcagType(values[0])
    SetSelectedWcagType(values[0]?.value)
    var domainsec = "";
    callUserdataApi("", values[0]?.value, domainsec = "")
  }
  const [RunReportPopShow, SetRunReportPopShow] = useState(false)
  const [RunReportType, SetRunReportType] = useState('accessibility2')

  const [dEngineoption, SetdEngineoption] = useState({
    completed_at:
      'Select Date',
    id:
      'Select Date',
  });
  const pdfdown = async () => {
    setLoading(true);
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}technical/accessibility2_table/?user_domain_id=${esodata.domaindata.value.header_selected}&job_id=${jobidValue}&formats=pdf&timezone=${timezoneval()}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/pdf',
          Authorization: `${ApiToken}`,
        },
      });
      // Check if the request was successful (status code 200)
      if (response.ok) {
        // Convert the response to a blob
        const blob = await response.blob();

        // Create a temporary anchor element
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);

        var filedata = await Commonfun("AccessibilityreportList", "pdf", ApiToken, esodata.domaindata.value.header_selected);
        // return false;
        link.download = filedata;
        link.click();

        // Remove the anchor element
        window.URL.revokeObjectURL(link.href);
      } else {
        if (response?.status == 599 || response?.status == 500 || response?.status == 400 || response?.status == 404) {
          response.json().then(data => {
            setapiOpen(true);
            setapierrorMsg(data?.error)
            console.log('Resolved object:', data.error);
          })
        }
        else if (response?.code == "ERR_NETWORK") {
          navigate('/errorpages')
        }
      }
    } catch (error) {
      toast.error("No Data", {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      console.error('Error during PDF download:', error);
    }
    setLoading(false);

  }
  const csvdown = async () => {
    setLoading(true);
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}technical/accessibility2_table/?user_domain_id=${esodata.domaindata.value.header_selected}&job_id=${jobidValue}&formats=csv&timezone=${timezoneval()}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/pdf',
          Authorization: `${ApiToken}`,
        },
      });
      // Check if the request was successful (status code 200)
      if (response.ok) {
        // Convert the response to a blob
        const blob = await response.blob();

        // Create a temporary anchor element
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        var filedata = await Commonfun("AccessibilityreportList", "csv", ApiToken, esodata.domaindata.value.header_selected);
        // return false;
        link.download = filedata;
        link.click();
        // Trigger a click on the anchor element to start the download
        link.click();

        // Remove the anchor element
        window.URL.revokeObjectURL(link.href);
      } else {
        if (response?.status == 599 || response?.status == 500 || response?.status == 400 || response?.status == 404) {
          response.json().then(data => {
            setapiOpen(true);
            setapierrorMsg(data?.error)
            console.log('Resolved object:', data.error);
          })
        }
        else if (response?.code == "ERR_NETWORK") {
          navigate('/errorpages')
        }
      }
    } catch (error) {
      toast.error("No Data", {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      console.error('Error during PDF download:', error);
    }
    setLoading(false);
  }
  // const LoadAddFormFilterOptions = async () => {
  //   SetSectionOptions([]);
  //   try {
  //     const doaminsectiondatavalue = await axios.get(
  //       `${process.env.REACT_APP_API_URL}domain/get_domain_sections/?user_domain_id=${esodata.domaindata.value.header_selected}`,
  //       {
  //         headers: {
  //           Authorization: `${ApiToken}`,
  //         },
  //       }
  //     );
  //     if (doaminsectiondatavalue.data) {
  //       if (doaminsectiondatavalue.data.domain_sections) {

  //         SetDoaminSectionUrl(doaminsectiondatavalue.data.domain_sections)
  //         var Sections = [];
  //         doaminsectiondatavalue.data.domain_sections.forEach((element) => {
  //           Sections.push({ label: element.name, value: element.id });
  //         });
  //         SetDomainsectiondata(Sections[0].value)
  //         SetSectionOptions(Sections);
  //       }
  //     }
  //   } catch (err) {}
  // } 
  const SetSectionNameHandle = (passvalue) => {
    SetDomainsectiondata(passvalue[0].value)
    callUserdataApi("", '', passvalue[0].value);
  }
  const handleToggle = () => {
    if (faqData != 1) {
      setfaqData(1)
    }
    else {
      setfaqData(2)
    }
    setToggle((pre) => !pre);
  };
  const e = document.getElementById('root');
  e.addEventListener('click', function handleClick(event) {
    console.log(event.target.classList[0])
    if ((event.target.classList[0] === 'cnt_hdr_blw_wrap') || (event.target.classList[0] === 'header_wrap') ||
      (event.target.classList[0] === 'nav_key_txt') ||
      (event.target.classList[0] === 'cnt_hdr_top_wrap') || (event.target.classList[0] === 'react-dropdown-select')
      || (event.target.classList[0] === 'react-dropdown-select-content')
      || (event.target.classList[0] === 'react-dropdown-select-item')
      || (event.target.classList[0] === 'site_cnt_hdr_blw_wrap') || (event.target.classList[0] === 'App')
      || (event.target.classList[0] === 'cnt_audit_outer_wrap') || (event.target.classList[0] === 'MuiDataGrid-columnHeaderTitleContainer')
      || (event.target.classList[0] === 'MuiDataGrid-toolbarContainer') || (event.target.classList[0] === 'MuiDataGrid-row')
      || (event.target.classList[0] === 'MuiDataGrid-cell') || (event.target.classList[0] === 'MuiDataGrid-cellContent')
      || (event.target.classList[0] === 'header_inner_btm_wrap') || (event.target.classList[0] === 'over_all_container')
      || (event.target.classList[0] === 'input_domain_field') || (event.target.classList[0] === 'react-dropdown-select-input')
      || (event.target.classList[0] === 'run_rpt_btn')
    ) {

      setfaqData(2)
      setToggle(false)
    }
  });
  return (

    <div className={`over_all_container ${isMenuExpanded ? "blur overflow-hidden" : ""}`}>
      {loading === true ? <Loader /> : null}
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <Helmet>
        <meta charSet="utf-8" />
        <title>Accessibility Report</title>
      </Helmet>
      <div className="cnt_audit_outer_wrap">
        <div className="cnt_hdr_top_wrap">
          <div className="cnt_hdr_top_lft_wrap">
            <h2 className="heading_font">Accessibility Report List</h2>
            {/* <p>as of {jobDate}</p> */}
            <p>as of {timezonechange(jobDate)}  <span style={{ fontWeight: 'bold' }}> - {timezoneval()} </span></p>

          </div>
          <div className="cnt_hdr_top_rgt_wrap">
            <div className={`App ${faqData == "1" ? 'faq_move_class' : ""}`} >
              <button onClick={handleToggle} className="faq_class" title="Frequently Asked Question">
                <QuestionMarkIcon />

              </button>
              {toggle && <Rightsidebar close={() => setToggle(false)} initialfaqDateval={initialfaqDateval} />}
              <img src={pdficon} alt="pdf icon" onClick={pdfdown} />
              <img src={csvicon} alt="csv icon" onClick={csvdown} />
            </div>

          </div>
        </div>
        <div className="link_cnt_hdr_blw_wrap">
          <Link to="/accessibility-score" ><button type="button" className={`site_rn_rpt_btn`}  >Score</button></Link>
          <Link to="/accessibility-summary" ><button type="button" className={`site_rn_rpt_btn `}  >Summary</button></Link>
          <Link to="/accessibility-report" ><button type="button" className={`site_rn_rpt_btn active`}  >Report</button></Link>
          <Link to="/accessibility-file" ><button type="button" className={`site_rn_rpt_btn `}  >File Accessibility</button></Link>
        </div>

        <div className="cnt_hdr_blw_wrap">
          <button className="run_rpt_btn rn_rpt_btn" onClick={() => { SetRunReportPopShow(true) }}>
            Run Report Now
          </button>
          <button className="run_rpt_btn ch_date_btn" onClick={openModal}>
            Filter By
            <span className="_btn_icon_calender">
              <img src={calendarpng} alt="calender logo" className="zoom" />
            </span>
          </button>

        </div>
        <div class="row pie_chart_row acc-rpt-list" style={{ "margin-top": "inherit" }}>
          {cardData.length > 0 ?
            <img src={`${process.env.REACT_APP_API_URL}technical/accessibility2_image/?user_domain_id=${esodata.domaindata.value.header_selected}&job_id=${jobidValue}&token=${result[1]}`} style={{ width: "500px", height: "300px", alignItems: "center", marginLeft: "300px" }} /> : <img src={errorimages} alt="errimg" title="Errorimage" style={{ width: "500px", height: "300px", alignItems: "center", marginLeft: "300px" }} />}
        </div>

        <div className="data_table accessbility_score_table accessbility_report_score_table" style={{ width: "100%" }}>
          <div style={{ height: 550, width: "100%" }}>
            <DataGrid

              slots={{
                noRowsOverlay: MyCustomNoRowsOverlay,
                toolbar: GridToolbar
              }}
              slotProps={{
                toolbar: {

                  showQuickFilter: true,

                },
              }}
              sx={{
                "&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell": {
                  py: "8px",
                },
                "&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell": {
                  py: "15px",
                },
                "&.MuiDataGrid-root--densityComfortable .MuiDataGrid-cell": {
                  py: "22px",
                },
                "& .MuiDataGrid-columnHeaderTitle": {
                  whiteSpace: "normal",
                  lineHeight: "normal",
                },
                "&, [class^=MuiDataGrid]": { border: "none" },
                ".MuiDataGrid-virtualScroller": {
                  height: "auto !important",
                  overflowY: "auto",
                },
                "& .MuiDataGrid-cell:first-child": {
                  position: isTabletView ? "relative" : "sticky",
                  top: "0",
                  left: "0",
                  paddingLeft: "1.5rem",
                  zIndex: 999,
                  backgroundColor: "#e5e6e9",
                },

                /* Second column */
                "& .MuiDataGrid-cell:nth-child(2)": {
                  position: isTabletView ? "relative" : "sticky",
                  top: "0",
                  left: "100px",  /* Adjust the left value based on the width of the first column */
                  zIndex: 998,
                  backgroundColor: "#e5e6e9",
                },
                /* Third column */
                "& .MuiDataGrid-cell:nth-child(3)": {
                  position: isTabletView ? "relative" : "sticky",
                  top: "0",
                  left: "650px",  /* Adjust the left value based on the combined width of the first and second columns */
                  zIndex: 997,
                  backgroundColor: "#e5e6e9",
                },
                // "& .MuiDataGrid-columnHeader:first-child": {
                //   position: "sticky",
                //   top: "0",
                //   left: "0",
                //   paddingLeft: "1.5rem",
                //   border: "none",
                //   zIndex: 999,
                // },
                "& .MuiDataGrid-columnHeaders": {
                  position: "sticky",
                  "& .MuiDataGrid-columnHeadersInner": {
                    transform: "none !important",
                    "& div": {
                      "& .MuiDataGrid-columnHeader:first-child": {
                        backgroundColor: "#fe874e",

                        zIndex: 999,
                      },

                    },
                  }
                },
                "& .MuiDataGrid-columnSeparator--sideRight": {
                  display: "none"
                }
              }}
              rows={rows}
              columns={columns}
              getRowHeight={() => 'auto'}
              autoHeight={autoheightval ? true : false}
              initialState={{
                pinnedColumns: { left: [''] },
                pagination: {
                  paginationModel: {
                    pageSize: 10,
                  },
                },
              }}
              pageSizeOptions={[10, 30, 50, 75, 100]}
              disableColumnFilter
              // loading={loading}
              disableColumnSelector
              disableDensitySelector
            />
          </div>
        </div>





        <Dialog
          open={modalIsOpen}
          onClose={closeModal}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          className="competitor_test_filter"
        >
          <DialogTitle>{"Filter By"}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              <div className="cnt_hdr_blw_wrap row">

                <div>

                  <div className="" style={{ marginBottom: '10px' }}>
                    <div>
                      <span>Completed Date</span>
                      <Select
                        values={[dEngineoption]}
                        options={completedate}
                        labelField="completed_at"
                        valueField="id"
                        onChange={(values) => {
                          OnsetSelectval(values)
                          SetdEngineoption({
                            completed_at:
                              values[0].completed_at,
                            id:
                              values[0].id,
                          });

                        }}
                      />
                    </div>


                  </div>



                  <div className="col">
                    <span className="text-error">{validation.dateerror}</span>
                  </div>



                </div>

              </div>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <button className="run_rpt_btn rn_rpt_btn" onClick={reportNow} style={{ cursor: 'pointer' }} >
              Submit
            </button>
            <button style={{ cursor: 'pointer' }} onClick={closeModal}>close</button>
          </DialogActions>
        </Dialog>



        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle>{"Error Message "}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              No Data Found
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Close</Button>
          </DialogActions>
        </Dialog>
        <Dialog
          open={apiopen}
          onClose={apihandleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle>{"Alert!! "}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              {apierrorMsg}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={apihandleClose}>Close</Button>
          </DialogActions>
        </Dialog>
      </div>
      <RunReportNow RunReportPopShow={RunReportPopShow} SetRunReportPopShow={SetRunReportPopShow} RunReportType={RunReportType} />
    </div>
  );
}
