import React, { useState , useEffect,useContext} from "react";
import pdficon from "./../Asset/PDF.png";
import csvicon from "./../Asset/CSV.png";
import calendarpng from "./../Asset/New_Assert/ESO_Icon/Filter.svg";
import ExternalLinkListData from "./ExternalLinkLists";
import { Outlet, Link } from "react-router-dom";
import RunReportNow from "../RunReportNow";
import { useSelector } from "react-redux";
import { ToastContainer, toast } from 'react-toastify';
import QuestionMarkIcon from "@mui/icons-material/QuestionMark";
import Keywordbar from "./../Rightsidebar/Keywordbar";
import axios from "axios";
import { Commonfun } from './../Commonfun';
import { timezonechange } from './../Commonfun';
import { timezoneval } from './../Commonfun';
import Loader from "../CommonLoader";
import { useNavigate } from "react-router-dom";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import { AppContext } from "../../../Context/Context";
const ExternalLinkLists=()=>{


  const {isMenuExpanded, setIsMenuExpanded} = useContext(AppContext);

    const [jobDate,SetjobDate]=useState('')
    const [modalIsOpen, setIsOpen] =useState(false);
    const [toggle, setToggle] = useState(false);
    const [faqData,setfaqData]=useState("2")
    const [initialDateval, SetInitialDateval] = useState([]); 
    function openModal() {
        setIsOpen(true);
      }
      function closeModal() {
        setIsOpen(false);
      }
      const apihandleClose = () => {
        setapiOpen(false);
      };

      const [RunReportPopShow,SetRunReportPopShow]=useState(false)
      const [RunReportType,SetRunReportType]=useState('link_checker')
      const [loading, setLoading] = useState(false);
      const esodata = useSelector((state) => state);
      const ApiToken=esodata.apiauthkey.value
  const [autoheightval,SetAutoheightval]=useState(true)
  const [apiopen, setapiOpen] = React.useState(false);
  const [apierrorMsg,setapierrorMsg]=useState();
  const navigate = useNavigate();
      const pdfdown = async () =>{ 

        setLoading(true);
        try{
          const response = await fetch(`${process.env.REACT_APP_API_URL}technical/external_links/?user_domain_id=${esodata.domaindata.value.header_selected}&job_id=&formats=pdf&timezone=${timezoneval()}`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/pdf',
                        Authorization: `${ApiToken}`,
                    },
                });
                // Check if the request was successful (status code 200)
                if (response.ok) {
                    // Convert the response to a blob
                    const blob = await response.blob();
      
                    // Create a temporary anchor element
                    const link = document.createElement('a');
                    link.href = window.URL.createObjectURL(blob);
                    var filedata=await Commonfun("Externallink","pdf", ApiToken,esodata.domaindata.value.header_selected);
                    // return false;
                    link.download = filedata;
                    link.click();  
      
                    // Remove the anchor element
                    window.URL.revokeObjectURL(link.href);
                } else {
                  if(response?.status==599 || response?.status==500 || response?.status==400  || response?.status==404 ){
                    response.json().then(data => {
                      setapiOpen(true);
                      setapierrorMsg(data?.error)
                      console.log('Resolved object:', data.error);
                    })
                  }
                  else if(response?.code =="ERR_NETWORK"){
                    navigate('/errorpages')    
                  }
                }
            } catch (error) {
              toast.error("No Data", {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
                });
                console.error('Error during PDF download:', error);
            }
            setLoading(false);
      
      }
      const csvdown = async () =>{ 

        setLoading(true);
        try{
          const response = await fetch(`${process.env.REACT_APP_API_URL}technical/external_links/?user_domain_id=${esodata.domaindata.value.header_selected}&job_id=&formats=csv&timezone=${timezoneval()}`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/pdf',
                        Authorization: `${ApiToken}`,
                    },
                });
                // Check if the request was successful (status code 200)
                if (response.ok) {
                    // Convert the response to a blob
                    const blob = await response.blob();
      
                    // Create a temporary anchor element
                    const link = document.createElement('a');
                    link.href = window.URL.createObjectURL(blob);
                    var filedata=await Commonfun("Externallink","csv", ApiToken,esodata.domaindata.value.header_selected);
                    // return false;
                    link.download = filedata;
                    link.click();  
                    // Remove the anchor element
                    window.URL.revokeObjectURL(link.href);
                } else {
                  if(response?.status==599 || response?.status==500 || response?.status==400  || response?.status==404 ){
                    response.json().then(data => {
                      setapiOpen(true);
                      setapierrorMsg(data?.error)
                      console.log('Resolved object:', data.error);
                    })
                  }
                  else if(response?.code =="ERR_NETWORK"){
                    navigate('/errorpages')    
                  }
                }
            } catch (error) {
              if(error?.status==599 || error?.status==500 || error?.status==400  || error?.status==404 ){
                error.json().then(data => {
                  setapiOpen(true);
                  setapierrorMsg(data?.error)
                  console.log('Resolved object:', data.error);
                })
              }
              else if(error?.code =="ERR_NETWORK"){
                navigate('/errorpages')    
              }
            }
            setLoading(false);
      
      }
      useEffect(() => {
        faqdatafun();
        
      }, []);
      const e = document.getElementById('root');
      e.addEventListener('click', function handleClick(event) {
        console.log(event.target.classList[0])
        if ((event.target.classList[0] === 'cnt_hdr_blw_wrap') || (event.target.classList[0] === 'header_wrap') || 
        (event.target.classList[0] === 'nav_key_txt') || 
        (event.target.classList[0] === 'cnt_hdr_top_wrap') ||(event.target.classList[0] === 'react-dropdown-select') 
        ||(event.target.classList[0] === 'react-dropdown-select-content') 
        ||(event.target.classList[0] === 'react-dropdown-select-item') 
        || (event.target.classList[0] === 'site_cnt_hdr_blw_wrap')   || (event.target.classList[0] === 'App')  
        || (event.target.classList[0] === 'cnt_audit_outer_wrap') || (event.target.classList[0] === 'MuiDataGrid-columnHeaderTitleContainer')
         || (event.target.classList[0] === 'MuiDataGrid-toolbarContainer') || (event.target.classList[0] === 'MuiDataGrid-row') 
         || (event.target.classList[0] === 'MuiDataGrid-cell')  || (event.target.classList[0] === 'MuiDataGrid-cellContent')
         || (event.target.classList[0] === 'header_inner_btm_wrap')||(event.target.classList[0] === 'over_all_container') 
         ||(event.target.classList[0] === 'input_domain_field') ||(event.target.classList[0] === 'react-dropdown-select-input')
         ||(event.target.classList[0] === 'run_rpt_btn') ||(event.target.classList[0] === 'urlcheck') 
         ||(event.target.classList[0] === 'link_cnt_hdr_blw_wrap') ||(event.target.classList[0] === 'MuiDataGrid-columnHeaderTitle') 
         ||(event.target.classList[0] === 'cols') ||(event.target.classList[0] === 'card_num_class') 
         ||(event.target.classList[0] === 'content_col') || (event.target.classList[0] ==='link_rn_rpt_btn') 
         || (event.target.classList[0] ==='css-b5h07o') || (event.target.classList[0] ==='site_regress_chart_info') 
          || (event.target.classList[0] ==='linechartdesign')
        ) {
          setfaqData(2)
          setToggle(false)   
        }
    });
      const faqdatafun = async(menubutval="") => {
        var datavar=`${process.env.REACT_APP_API_URL}domain/frequently_asked_questions/?menu=23`
        try {
          const doaminsectiondatavalue = await axios.get(
            datavar,
            {
              headers: {
                Authorization: `${ApiToken}`,
              },
            }
          );
          if (doaminsectiondatavalue.data) {
           
            SetInitialDateval(doaminsectiondatavalue?.data?.faqs)
          }
        } catch (err) {}
      }
      const handleToggle = () => {
        if(faqData!=1){
          setfaqData(1)
        }
        else{
          setfaqData(2)
        }
            setToggle((pre) => !pre);
      };
    return (
        <>
      {loading === true ? <Loader /> : null}

            <ToastContainer
position="top-center"
autoClose={5000}
hideProgressBar={false}
newestOnTop={false}
closeOnClick
rtl={false}
pauseOnFocusLoss
draggable
pauseOnHover
theme="light"
/>
      <div className={`over_all_container ${isMenuExpanded ? "blur overflow-hidden" : ""}`}>
    <div className="cnt_audit_outer_wrap">
      <div className="cnt_hdr_top_wrap">
        <div className="cnt_hdr_top_lft_wrap">
          <h2 className="heading_font">External Link Checker</h2>
          {/* <p>as of {jobDate}</p> */}
         {/* <p>as of {timezonechange(jobDate)}  <span style={{ fontWeight: 'bold' }}> - {timezoneval()} </span></p> */}
         <p>as of {jobDate}  <span style={{ fontWeight: 'bold' }}> - {timezoneval()} </span></p>


        </div>
        <div className="cnt_hdr_top_rgt_wrap int-link-check">
        <div className={`App ${faqData=="1"?'faq_move_class':""}`} >
            <button onClick={handleToggle} className="faq_class"  title="Frequently Asked Question">
            <QuestionMarkIcon />
              
            </button>
           {toggle && <Keywordbar close={() => setToggle(false)} initialDateval={initialDateval} />} 
           <img src={pdficon} alt="pdf icon" onClick={pdfdown}/>
           <img src={csvicon} alt="csv icon" onClick={csvdown} />
          </div>
       
        </div>
      </div>
      
      <div className="link_cnt_hdr_blw_wrap">

      <Link to="/internal-link-checker"> <button className="link_rn_rpt_btn">
      Internal Link Checker
        </button></Link>
 
        <Link to="/external-link-checker"> <button className="link_rn_rpt_btn active">
          External Link Checker
        </button></Link>
       
      </div>


      <div className="site_blw_wrap">
        <div className="cnt_hdr_blw_wrap">
          <button className="run_rpt_btn rn_rpt_btn" onClick={()=>{SetRunReportPopShow(true)}}>
            Run Report Now
          </button>
          <button className="run_rpt_btn ch_date_btn" onClick={openModal}>
            Filter By
            <span className="_btn_icon_calender">
              <img src={calendarpng} alt="calender logo" />
            </span>
          </button>
        </div>
      </div>



  
     
  

  <ExternalLinkListData SetjobDate={SetjobDate} modalIsOpen={modalIsOpen} setIsOpen={setIsOpen}/>
     
   
    
    </div>
    </div>
    <RunReportNow RunReportPopShow={RunReportPopShow} SetRunReportPopShow={SetRunReportPopShow} RunReportType={RunReportType}/>
    <Dialog
          open={apiopen}
          onClose={apihandleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle>{"Alert!! "}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              {apierrorMsg}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={apihandleClose}>Close</Button>
          </DialogActions>
        </Dialog>

        
        </>
    )
}
export default ExternalLinkLists